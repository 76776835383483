import React from "react";
import Button from "../Button/Button";
import ErrorIcon from "../../../assets/svg/error-emblem.svg";
import closeIcon from "../../../assets/svg/close-dark.svg";

import "./ConfirmJobPayment.scss";
import { useTranslation } from "react-i18next";
import cs from "classnames";

interface ConfirmJobPaymentModalProps {
    onCancel: () => void;
    onSubmit: () => void;
    title?: string;
    subTitle?: string;
    buttonLabel: string;
    hideCancelBtn?: boolean;
    isLongTerm?: boolean;
}

const ConfirmJobPaymentModal = (props: ConfirmJobPaymentModalProps) => {
    const { t } = useTranslation("common");

    return (
        <div className="ConfirmJobPaymentModal">
            <div className="ConfirmJobPaymentModal__container">
                <img
                    className="ConfirmJobPaymentModal__close"
                    alt="close icon"
                    src={closeIcon}
                    onClick={() => {
                        props.onCancel();
                    }}
                />
                <img src={ErrorIcon} alt="alert icon" className="ConfirmJobPaymentModal__icon"/>
                <h2 className="ConfirmJobPaymentModal__label">{props.title}</h2>

                {/* Scrollable SchedulePlanButton Container */}
                <div className="ConfirmJobPaymentModal__scrollableContent">
                    {props.isLongTerm ? (
                        <div className="SchedulePlanButton">
                            <div className="SchedulePlanButton__price_hint">
                                {t("jobs.newJob.newShortTermButton.octoberOffer")}
                            </div>
                            <div className="SchedulePlanButton__body">
                                <div className="SchedulePlanButton__priceContainer">
                                    <span className="SchedulePlanButton__price--new">00.00€</span>
                                    <span className="SchedulePlanButton__price--old">50.00€</span>
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionThree")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionFour")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionFive")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionSix")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionSeven")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionEight")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionNine")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionTen")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionEleven")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionTwelve")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newLongTermButton.descriptionThree")}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="SchedulePlanButton">
                            <div className="SchedulePlanButton__price_hint">
                                {t("jobs.newJob.newShortTermButton.octoberOffer")}
                            </div>
                            <div className="SchedulePlanButton__body">
                                <div className="SchedulePlanButton__priceContainer">
                                    <span className="SchedulePlanButton__price--new">00.00€</span>
                                    <span className="SchedulePlanButton__price--old">10.00€</span>
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionThree")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionFour")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionFive")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionSix")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionSeven")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionEight")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionNine")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionTen")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionEleven")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionTwelve")}
                                </div>
                                <div className="SchedulePlanButton__paragraph">
                                    • {t("jobs.newJob.newShortTermButton.descriptionFourteen")}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="ConfirmJobPaymentModal__buttons">
                    <Button
                        type="submit"
                        style={{height: "60px", marginLeft: "5px"}}
                        label={props.buttonLabel}
                        onClick={() => {
                            props.onSubmit();
                        }}
                        className="ConfirmJobPaymentModal__submit-btn"
                    />
                </div>
            </div>
        </div>

    );
};

export default ConfirmJobPaymentModal;
