import axios from "axios";
import { auth } from "../firebaseConfig";

export const apiCall = async (method: string, url: string, data?: any) => {
  const accessToken = await auth.currentUser?.getIdToken();
  const response = await axios({
    method: method,
    url: `${process.env.REACT_APP_API_BASE_URL}/${url}`,
    data: data,
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

  return response.data;
};

export const apiCallWithoutAuth = async (
  method: string,
  url: string,
  data?: any,
) => {
  const response = await axios({
    method: method,
    url: `${process.env.REACT_APP_API_BASE_URL}/${url}`,
    data: data,
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
    },
  });

  return response.data;
};
