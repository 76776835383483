import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import {useCreateCompanyFormik} from "./useCreateCompanyFormik";
import {apiCall} from "../../../api/Api";
import {useNavigate} from "react-router-dom";
import {useGlobalContext} from "../../../context/GlobalContext/GlobalContext";
import {useAuthContext} from "../../../context/AuthContext/AuthContext";
import Mapbox, {LocationType} from "../../../components/shared/Mapbox/Mapbox";
import {convertRestApiErrorCodes} from "../../../lib/helpers/handleErrors";
import FileUploader from "../../../components/shared/FileUploader/FileUploader";
import InputField from "../../../components/shared/InputField/InputField";
import Button from "../../../components/shared/Button/Button";
import TextAreaInput from "../../../components/shared/TextAreaInput/TextAreaInput";
import ModalContainer from "../../../components/shared/ModalContainer/ModalContainer";
import companyAvatar from "../../../assets/images/company-avatar.png";
import penLogo from "../../../assets/svg/pen-logo.svg";
import deleteIcon from "../../../assets/svg/delete-icon.svg";
import markerIcon from "../../../assets/svg/marker.svg";

import "./CreateCompanyForm.scss";
import ksFlag from "../../../assets/flags/kosova.svg";

const CreateCompanyForm = () => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isImageUploaderOpen, setIsImageUploaderOpen] = useState(false);
  const [base64Image, setBase64Image] = useState("");
  const [base64ImageError, setBase64ImageError] = useState(false);
  const {t} = useTranslation("common");
  const navigate = useNavigate();
  const globalCtx = useGlobalContext();
  const authCtx = useAuthContext();

  const formik = useCreateCompanyFormik({
    onSubmit: async (values: any) => {
      console.log("Form submitted:", values);
      if (base64Image.length === 0) {
        setBase64ImageError(true);
        return;
      }
      try {
        setIsButtonLoading(true);
        const profileDataObject = {
          display_name: values.nameSurname,
          language: globalCtx.selectedLanguage.keyword,
          phone_number: `+383${values.phoneNumber}`
        };

        const data = {
          name: values.companyName,
          unique_business_identifier: values.companyNumber,
          description: values.companyDescription,
          logo_data: base64Image,
          address: values.companyAddress,
          city: values.companyCity,
          country: 'XK'
        };

        // CREATE USER PROFILE
        await apiCall("POST", "b2b/user/profile/", profileDataObject);

        // CREATE COMPANY
        const companyData = await apiCall("POST", "b2b/organization/", data);

        // GET USER PROFILE
        const profileData = await apiCall("GET", "b2b/user/profile/");

        // SAVE PROFILE DATA TO LOCAL STORAGE AND RESET STATE
        authCtx.setDataToLocalStorage(
          profileData.data.profile,
          companyData.data,
        );
        // PROCEED
        navigate("/jobs/new-job");
      } catch (error: any) {
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
      } finally {
        setIsButtonLoading(false);
      }
    },
  });
  return (
    <>
      {isImageUploaderOpen && (
        <ModalContainer>
          <FileUploader
            maxFileSizeInBytes={5242880}
            accept="image/*"
            onCancel={() => {
              setIsImageUploaderOpen(false);
            }}
            onSubmit={(fileName, base64) => {
              if (base64 !== undefined) {
                setBase64Image(base64);
                setIsImageUploaderOpen(false);
                setBase64ImageError(false);
              }
            }}
          />
        </ModalContainer>
      )}
      <div className="CreateCompanyForm">
        <p className="CreateCompanyForm__description">
          {t("createCompany.companyDetails.form.description")}
        </p>
        <div className="CreateCompanyForm__body">
          <div className="CompanyDetails">
            <div className="CompanyDetails__body">
              <div className="CompanyDetails__input">
                <div className="CompanyDetails__input-row">
                  <div className="CompanyDetails__uploader">
                    <div className="CompanyDetails__circle">
                      {base64Image === "" ? (
                        <img
                          alt="pic"
                          src={companyAvatar}
                          className="CompanyDetails__placeholder"
                        />
                      ) : (
                        <img
                          alt="pic"
                          src={base64Image}
                          className="CompanyDetails__image"
                        />
                      )}

                      <img
                        alt="pic"
                        src={base64Image === "" ? penLogo : deleteIcon}
                        className="CompanyDetails__pen"
                        onClick={() => {
                          base64Image === ""
                            ? setIsImageUploaderOpen(true)
                            : setBase64Image("");
                        }}
                      />
                    </div>

                  </div>

                  <InputField
                    type="text"
                    placeholder={t("createCompany.companyDetails.form.companyName.placeholder")}
                    name="companyName"
                    value={formik.values.companyName}
                    onBlur={(e: any) => formik.handleBlur(e)}
                    onChange={(e: any) => formik.handleChange(e)}
                  />
                </div>
                <div className="CompanyDetails__input-description">
                  {t("createCompany.companyDetails.form.companyName.description")}
                </div>
                {formik.errors.companyName && formik.touched.companyName && (
                  <h5 className="CompanyDetails__input-error">
                    {formik.errors.companyName as string}
                  </h5>
                )}
                {base64Image === '' && formik.touched.companyName && (
                  <h5 className="CompanyDetails__input-error">
                    {t("createCompany.companyDetails.form.companyLogoError")}
                  </h5>
                )}
              </div>
              {" "}
              <div className="CompanyDetails__input">
                <InputField
                  type="text"
                  placeholder={t(
                    "createCompany.companyDetails.form.companyNumber.placeholder",
                  )}
                  name="companyNumber"
                  value={formik.values.companyNumber}
                  onBlur={(e: any) => formik.handleBlur(e)}
                  onChange={(e: any) => formik.handleChange(e)}
                />
                <div className="CompanyDetails__input-description">
                  {t(
                    "createCompany.companyDetails.form.companyNumber.description",
                  )}
                </div>
                {formik.errors.companyNumber &&
                  formik.touched.companyNumber && (
                    <h5 className="CompanyDetails__input-error">
                      {formik.errors.companyNumber as string}
                    </h5>
                  )}
              </div>
              {" "}

              <div className="CompanyDetails__input">
                <div className="CompanyDetails__input-row">
                  <InputField
                    type="text"
                    placeholder={t(
                      "createCompany.companyDetails.form.companyAddress.placeholder",
                    )}
                    name="companyAddress"
                    value={formik.values.companyAddress}
                    onBlur={(e: any) => formik.handleBlur(e)}
                    onChange={(e: any) => formik.handleChange(e)}
                  />
                  <InputField
                    type="text"
                    placeholder={t(
                      "createCompany.companyDetails.form.companyCity.placeholder",
                    )}
                    name="companyCity"
                    value={formik.values.companyCity}
                    onBlur={(e: any) => formik.handleBlur(e)}
                    onChange={(e: any) => formik.handleChange(e)}
                  />
                </div>
                <div className="CompanyDetails__input-description">
                  {t(
                    "createCompany.companyDetails.form.companyAddress.description",
                  )}
                </div>
                {formik.errors.companyAddress && formik.touched.companyAddress && (
                  <h5 className="CompanyDetails__input-error">
                    {formik.errors.companyAddress as string}
                  </h5>
                )}
                {formik.errors.companyCity && formik.touched.companyCity && (
                  <h5 className="CompanyDetails__input-error">
                    {formik.errors.companyCity as string}
                  </h5>
                )}
              </div>
              {" "}
              <div className="CompanyDetails__input">
                <div className="SendMessage__inputs">
                  <InputField
                    onBlur={() => {
                    }}
                    onChange={() => {
                    }}
                    placeholder="+383"
                    value="+383"
                    leftIcon={ksFlag}
                  />
                  <InputField
                    type="text"
                    placeholder={t(
                      "4xxxxxx",
                    )}
                    name="phoneNumber"
                    value={formik.values.phoneNumber}
                    onBlur={(e: any) => formik.handleBlur(e)}
                    onChange={(e: any) => formik.handleChange(e)}
                  />
                </div>
                <div className="CompanyDetails__input-description">
                  {t(
                    "verifyPhoneNumber.modal.sendMessage.title",
                  )}
                </div>

              </div>
              {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                <h5 className="CompanyDetails__input-error">
                  {formik.errors.phoneNumber as string}
                </h5>
              )}
              <div className="CompanyDetails__textArea">
                <TextAreaInput
                  placeholder={t(
                    "createCompany.companyDetails.form.companyDescription.placeholder",
                  )}
                  name="companyDescription"
                  value={formik.values.companyDescription}
                  onBlur={(e: any) => formik.handleBlur(e)}
                  onChange={(e: any) => formik.handleChange(e)}
                />
              </div>
              {formik.errors.companyDescription &&
                formik.touched.companyDescription && (
                  <h5 className="CompanyDetails__textArea-error">
                    {formik.errors.companyDescription as string}
                  </h5>
                )}
            </div>
            {base64ImageError && (
              <h5 className="CompanyDetails__uploader-error">
                {t("createCompany.companyDetails.form.companyLogoError")}
              </h5>
            )}
            <div className="CompanyDetails__footer">
              <div className="CompanyDetails__button">
                <Button
                  isLoading={isButtonLoading}
                  type="submit"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  label={t("createCompany.companyDetails.form.button")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCompanyForm;
