import React, { useEffect, useState } from "react";

import { apiCall } from "../../api/Api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../components/shared/LoadingComponent/LoadingComponent";

import plusIcon from "../../assets/svg/plus-icon-white.svg";
import PaymentCard from "../../components/shared/PaymentCard/PaymentCard";
import Invoice from "../../components/shared/Invoice/Invoice";
import ModalContainer from "../../components/shared/ModalContainer/ModalContainer";
import AddPaymentCard from "./AddPaymentCard/AddPaymentCard";

import "./Payments.scss";

const Payments = () => {
  const [paymentMethods, setPaymentMethods] = useState<any | null>();
  const [invoices, setInvoices] = useState<any | null>();
  const [isAddPaymentCardOpen, setIsAddPaymentCardOpen] = useState(false);
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  useEffect(() => {
    // GET PAYMENT METHODS
    apiCall("GET", "subscription/payment-methods/")
      .then((response) => {
        setPaymentMethods(response.data);
      })
      .catch((error) => {
        setPaymentMethods(null);
      });
  }, []);

  useEffect(() => {
    // GET INVOICES
    apiCall("GET", "subscription/invoices/")
      .then((response) => {
        setInvoices(response.data.data);
      })
      .catch((error) => {
        setInvoices(null);
      });
  }, []);

  // Reorder the payment methods array to have the default payment method first
  const reorderedPaymentMethods = paymentMethods?.data
    ? [
        ...paymentMethods.data.filter(
          (item: any) => item.id === paymentMethods.default_payment_method,
        ),
        ...paymentMethods.data.filter(
          (item: any) => item.id !== paymentMethods.default_payment_method,
        ),
      ]
    : [];

  return (
    <>
      {isAddPaymentCardOpen && (
        <ModalContainer position="top">
          <AddPaymentCard
            onCloseClick={() => {
              setIsAddPaymentCardOpen(false);
            }}
          />
        </ModalContainer>
      )}
      <div className="Payments">
        <div className="Payments__head">
          <p className="Payments__head-title">{t("payments.title")}</p>
        </div>
        <div className="Payments__body">
          <div className="PaymentCardsCart">
            <div className="PaymentCardsCart__header">
              <p className="PaymentCardsCart__title">
                {t("payments.paymentCardsCart.title")}
              </p>
              <p
                className="PaymentCardsCart__manage"
                onClick={() => {
                  navigate("/payments/payment-cards");
                }}
              >
                {t("payments.paymentCardsCart.manage")}
              </p>
            </div>
            <div className="PaymentCardsCart__body">
              {paymentMethods === undefined ? (
                <div className="PaymentCardsCart__loader">
                  <LoadingComponent />
                </div>
              ) : paymentMethods === null ? (
                <div
                  className="PaymentCardsCart__add"
                  onClick={() => {
                    setIsAddPaymentCardOpen(true);
                  }}
                >
                  <div className="PaymentCardsCart__add-round">
                    <img
                      alt="pic"
                      src={plusIcon}
                      className="PaymentCardsCart__add-plus"
                    />
                  </div>
                  <p className="PaymentCardsCart__add-text">
                    {t("payments.paymentCardsCart.addCart")}
                  </p>
                </div>
              ) : (
                <>
                  {reorderedPaymentMethods
                    ?.slice(0, 3)
                    .map((item: any, index: number) => (
                      <PaymentCard
                        key={index}
                        item={item}
                        defaultPaymentMethod={
                          paymentMethods.default_payment_method
                        }
                      />
                    ))}
                </>
              )}
            </div>
          </div>
          <div className="InvoicesCart">
            <div className="InvoicesCart__header">
              <p className="InvoicesCart__title">
                {t("payments.invoicesCart.title")}
              </p>
              <p
                className="InvoicesCart__viewAll"
                onClick={() => {
                  navigate("/payments/invoices");
                }}
              >
                {t("payments.invoicesCart.viewAll")}
              </p>
            </div>

            {invoices === undefined ? (
              <div className="InvoicesCart__loader">
                <LoadingComponent />
              </div>
            ) : invoices === null ? (
              <div className="InvoicesCart">{t("noData")}</div>
            ) : (
              <div className="InvoicesCart__body">
                {invoices
                  ?.slice(0, 3)
                  .map((item: any, index: number) => (
                    <Invoice key={index} invoice={item} />
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Payments;
