import {useEffect, useState} from "react";
import verified from "../../../../assets/svg/verified-icon-green.svg";
import calendarIcon from "../../../../assets/svg/calendar-icon.svg";
import startIcon from "../../../../assets/svg/start-icon.svg";
import endIcon from "../../../../assets/svg/end-shift-icon.svg";
import completedIcon from "../../../../assets/svg/completed.svg";
import inProgressIcon from "../../../../assets/svg/in-progresss-shift.svg";
import fullStar from "../../../../assets/svg/review-star-full.svg";
import emptyStar from "../../../../assets/svg/review-star-gray.svg";
import cs from "classnames";
import Button from "../../../../components/shared/Button/Button";
import ReviewModal from "../ReviewModal/ReviewModal";
import { useGlobalContext } from "../../../../context/GlobalContext/GlobalContext";
import WarningModal from "../../../../components/shared/WarningModal/WarningModal";
import axios from "axios";
import { auth } from "../../../../firebaseConfig";
import { useTranslation } from "react-i18next";
import { PhotoProvider, PhotoView } from "react-image-previewer";
import { CloseButton } from "react-image-previewer/ui";
import ContactModal from "../../../../components/shared/ContactModal/ContactModal";
import { convertRestApiErrorCodes } from "../../../../lib/helpers/handleErrors";

import "./AttendanceProfile.scss";
import { apiCall } from "../../../../api/Api";

const AttendanceProfile = (props: any) => {
  const { onClose, job_id, user_id, is_long_term} = props;
  // let localPerson = JSON.parse(localStorage.getItem("person")!!);
  let jobName = localStorage.getItem("jobName");
  // let jobId = localStorage.getItem("jobId");
  // let userId = localStorage.getItem("userId");

  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [getReview, setGetReview] = useState(false);
  const daysOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const [person, setPerson] =  useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);
  const [openUnAttend, setOpenUnAttend] = useState(false);
  const [applicantStatus, setApplicantStatus] = useState<string>("Personal Details");
  const globalCtx = useGlobalContext();
  const { t } = useTranslation("common");
  const [openContactModal, setOpenContactModal] = useState(false); // State for ContactModal
  const [jobId, setJobId] = useState(job_id);
  const [userId, setUserId] = useState(user_id);
  const [isLongTerm, setIsLongTerm] = useState(is_long_term);

  useEffect(() => {
    setUserId(user_id);
    setJobId(job_id);
  }, [job_id, user_id]);


  useEffect(() => {
    // if (!isCalled) {
      setIsLoading(true);
      apiCall("GET", `b2b/applications/${jobId}/profile/${userId}`)
          .then((res) => {
            setPerson(res.data);
            console.log("person", res.data);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
          });
    // }
  }, []);

  const handleClick = async () => {
    const accessToken = await auth.currentUser?.getIdToken();
    setIsLoading(true);


    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL}/b2b/applications/${person.id}/contract/${jobId}/`,
        headers: {
          Accept: "*/*",
          Authorization: accessToken ? `Bearer ${accessToken}` : null,
        },
      });
      const pdfUrl = response.data.data.contract_url;
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      globalCtx.openMessageModal(
        "success",
        t("staticMessage.viewContractSuccess"),
      );
      setIsLoading(false);
    } catch (error: any) {
      const errorMessage = convertRestApiErrorCodes(
        error?.response?.data?.code,
        t,
      );
      globalCtx.openMessageModal("error", errorMessage);
      setIsLoading(false);
    }
  };

  const handleComplete = () => {
    setIsLoading(true);
    apiCall("POST", `b2b/jobs/${jobId}/complete/${person.id}/`)
      .then((res: any) => {
        globalCtx.openMessageModal(
          "success",
          t("staticMessage.statusSetCompleted"),
        );
        setIsLoading(false);
        onClose();
      })
      .catch((err) => {
        const errorMessage = convertRestApiErrorCodes(
          err?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
        setIsLoading(false);
      });
  };
  const handleDidNotAttend = () => {
    setIsLoading(true);
    apiCall("POST", `b2b/jobs/${jobId}/mark-as-unattended/${person.id}/`)
      .then((res: any) => {
        globalCtx.openMessageModal(
          "success",
          t("staticMessage.statusSetDidNotAttend"),
        );
        setIsLoading(false);
        onClose();
      })
      .catch((err) => {
        const errorMessage = convertRestApiErrorCodes(
          err?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
        setIsLoading(false);
      });
  };

  return (
    <section className="AttendanceProfile">
      <div className="AttendanceProfile__info-card">
        <div className="AttendanceProfile__body">
          <div className="flex">
            <PhotoProvider
              maskOpacity={0.7}
              overlayRender={(props) => {
                return (
                  <>
                    <CloseButton onClick={props.onClose} />
                  </>
                );
              }}
            >
              <div className="AttendanceProfile__person-image-wrapper">
                <PhotoView src={person?.picture_url}>
                  <img
                    src={person?.picture_url}
                    alt="user"
                    className="AttendanceProfile__person-image"
                  />
                </PhotoView>
              </div>
            </PhotoProvider>
            <div>
              <div className="ApplicationCard__name-container">
                <h2 className="ApplicationCard__name">{`${person?.first_name} ${person?.last_name}`}</h2>
                {person.verified && (
                  <img
                    src={verified}
                    alt="verified icon"
                    className="ApplicationCard__verified-icon"
                  />
                )}
              </div>
              <div className="ApplicationCard__role">{jobName}</div>
              <div className="ApplicationCard__review-wrapper">
                <img
                  src={fullStar}
                  alt="star"
                  className="AttendanceProfile__review-star2"
                />
                {`${person?.average_rating}(${person?.total_reviews})`}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="ApplicantSidebar__container">

        <div
            onClick={() => setApplicantStatus("Personal Details")}
            className={cs(
                "ApplicantSidebar__container-item",
                applicantStatus === "Personal Details" &&
                "ApplicantSidebar__container-item--active",
            )}
        >
          {t("jobs.applicantSidebar.personalDetails.title")}
        </div>
        <div
            onClick={() => setApplicantStatus("Review")}
            className={cs(
                "ApplicantSidebar__container-item",
                applicantStatus === "Review" &&
                "ApplicantSidebar__container-item--active",
            )}
        >
          {t("jobs.attendanceProfile.review.title")}{" "}
        </div>
      </section>

      {applicantStatus === "Review" && (
          <div className="AttendanceProfile__review-card">
            {person.review === null ? (
                <div className="AttendanceProfile__no-review">
                  <p className="AttendanceProfile__no-review-text">
                    {" "}
                    {t("jobs.attendanceProfile.review.noReviewText")}
              </p>
              <button
                className="AttendanceProfile__no-review-btn"
                onClick={() => setOpenReviewModal(true)}
              >
                {t("jobs.attendanceProfile.review.addReview")}
              </button>
            </div>
          ) : (
            <div className="AttendanceProfile__review-card-container">
              <div className="AttendanceProfile__review-card-rating">
                <div className="AttendanceProfile__review-stars">
                  <img
                    src={
                      person?.review?.average_rating < 1 ? emptyStar : fullStar
                    }
                    alt="star"
                    className="AttendanceProfile__review-star"
                  />
                  <img
                    src={
                      person?.review?.average_rating < 2 ? emptyStar : fullStar
                    }
                    alt="star"
                    className="AttendanceProfile__review-star"
                  />
                  <img
                    src={
                      person?.review?.average_rating < 3 ? emptyStar : fullStar
                    }
                    alt="star"
                    className="AttendanceProfile__review-star"
                  />
                  <img
                    src={
                      person?.review?.average_rating < 4 ? emptyStar : fullStar
                    }
                    alt="star"
                    className="AttendanceProfile__review-star"
                  />
                  <img
                    src={
                      person?.review?.average_rating < 5 ? emptyStar : fullStar
                    }
                    alt="star"
                    className="AttendanceProfile__review-star"
                  />
                </div>
                {person?.review?.average_rating}
              </div>
              {person?.review?.review && (
                <div className="AttendanceProfile__review-text">
                  {person?.review?.review}
                </div>
              )}

              <div className="AttendanceProfile__review-stats">
                {/*  starts here */}
                <div className="AttendanceProfile__body-job-stat">
                  <div className="AttendanceProfile__body-job-stat-wrap">
                    <div className="AttendanceProfile__stat-label">
                      {" "}
                      {t("jobs.attendanceProfile.review.attendance")}
                    </div>
                    <div className="AttendanceProfile__stat-number">
                      {person?.review?.attendance_rating}
                    </div>
                  </div>
                  <div className="AttendanceProfile__stat-line">
                    {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                      <div
                        key={index}
                        className={cs(`AttendanceProfile__stat-line-one
                    ${person?.review?.attendance_rating === 1 && item === 1 && `AttendanceProfile__stat-line-one--red`}
                    ${person?.review?.attendance_rating <= 4 && person?.review?.attendance_rating > 1 && item <= person?.review?.attendance_rating && person?.review?.attendance_rating !== null && `AttendanceProfile__stat-line-one--yellow`}
                    ${person?.review?.attendance_rating === 5 && `AttendanceProfile__stat-line-one--green`}
                    `)}
                      />
                    ))}
                  </div>
                </div>
                <div className="AttendanceProfile__body-job-stat">
                  <div className="AttendanceProfile__body-job-stat-wrap">
                    <div className="AttendanceProfile__stat-label">
                      {" "}
                      {t("jobs.attendanceProfile.review.skills")}
                    </div>
                    <div className="AttendanceProfile__stat-number">
                      {person?.review?.skill_rating}
                    </div>
                  </div>
                  <div className="AttendanceProfile__stat-line">
                    {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                      <div
                        key={index}
                        className={cs(`AttendanceProfile__stat-line-one
                    ${person?.review?.skill_rating === 1 && item === 1 && `AttendanceProfile__stat-line-one--red`}
                    ${person?.review?.skill_rating <= 4 && person?.review?.skill_rating > 1 && item <= person?.review?.skill_rating && person?.review?.skill_rating !== null && `AttendanceProfile__stat-line-one--yellow`}
                    ${person?.review?.skill_rating === 5 && `AttendanceProfile__stat-line-one--green`}
                    `)}
                      />
                    ))}
                  </div>
                </div>
                <div className="AttendanceProfile__body-job-stat">
                  <div className="AttendanceProfile__body-job-stat-wrap">
                    <div className="AttendanceProfile__stat-label">
                      {" "}
                      {t("jobs.attendanceProfile.review.communication")}
                    </div>
                    <div className="AttendanceProfile__stat-number">
                      {person?.review?.communication_rating}
                    </div>
                  </div>
                  <div className="AttendanceProfile__stat-line">
                    {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                      <div
                        key={index}
                        className={cs(`AttendanceProfile__stat-line-one
                    ${person?.review?.communication_rating === 1 && item === 1 && `AttendanceProfile__stat-line-one--red`}
                    ${
                      person?.review?.communication_rating <= 4 &&
                      person?.review?.communication_rating > 1 &&
                      item <= person?.review?.communication_rating &&
                      person?.review?.communication_rating !== null &&
                      `AttendanceProfile__stat-line-one--yellow`
                    }
                    ${person?.review?.communication_rating === 5 && `AttendanceProfile__stat-line-one--green`}
                    `)}
                      />
                    ))}
                  </div>
                </div>
                <div className="AttendanceProfile__body-job-stat">
                  <div className="AttendanceProfile__body-job-stat-wrap">
                    <div className="AttendanceProfile__stat-label">
                      {t("jobs.attendanceProfile.review.teamWork")}
                    </div>
                    <div className="AttendanceProfile__stat-number">
                      {person?.review?.teamwork_rating}
                    </div>
                  </div>
                  <div className="AttendanceProfile__stat-line">
                    {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                      <div
                        key={index}
                        className={cs(`AttendanceProfile__stat-line-one
                    ${person?.review?.teamwork_rating === 1 && item === 1 && `AttendanceProfile__stat-line-one--red`}
                    ${person?.review?.teamwork_rating <= 4 && person?.review?.teamwork_rating > 1 && item <= person?.review?.teamwork_rating && person?.review?.teamwork_rating !== null && `AttendanceProfile__stat-line-one--yellow`}
                    ${person?.review?.teamwork_rating === 5 && `AttendanceProfile__stat-line-one--green`}
                    `)}
                      />
                    ))}
                  </div>
                </div>
                {/* ends here */}
              </div>
            </div>
          )}
        </div>
      )}
      {applicantStatus === "Personal Details" && (
        <div>
          <div className="AttendanceProfile__basic-info">
            <div className="AttendanceProfile__basic-info-item">
              <p className="AttendanceProfile__basic-info-label">
                {t("jobs.attendanceProfile.basicInfo.gender")}
              </p>
              <p className="AttendanceProfile__basic-info-value">
                {person?.gender === "M"
                  ? t("jobs.attendanceProfile.basicInfo.male")
                  : t("jobs.attendanceProfile.basicInfo.female")}
              </p>
            </div>
            <div className="ApplicantSidebar__personal-details-item">
              <p className="ApplicantSidebar__personal-details-label">
                {t("jobs.applicantSidebar.personalDetails.phoneNumber")}
              </p>
              <p
                className="ApplicantSidebar__personal-details-value"
                onClick={() =>
                  person?.phone_number && setOpenContactModal(true)
                }
              >
                {person?.phone_number ? (
                  <>
                    {person.phone_number}
                    <i
                      className="fa fa-phone"
                      aria-hidden="true"
                      style={{ marginLeft: "10px" }}
                    ></i>
                    <i
                      className="fa fa-whatsapp"
                      aria-hidden="true"
                      style={{ color: "#25D366", marginLeft: "10px" }}
                    ></i>
                  </>
                ) : (
                  t("jobs.applicantSidebar.personalDetails.noPhoneNumber")
                )}
              </p>
            </div>
            <div className="AttendanceProfile__basic-info-item">
              <p className="AttendanceProfile__basic-info-label">
                {t("jobs.attendanceProfile.basicInfo.birthDate")}
              </p>
              <p className="AttendanceProfile__basic-info-value">
                {new Date(person.date_of_birth).toLocaleString("en-GB", {
                  month: "short",
                  year: "numeric",
                  day: "numeric",
                })}
              </p>
            </div>
            <div className="AttendanceProfile__basic-info-item">
              <p className="AttendanceProfile__basic-info-label">
                {t("jobs.attendanceProfile.basicInfo.address")}
              </p>
              <p className="AttendanceProfile__basic-info-value">
                {person?.address}
              </p>
            </div>
            <div className="AttendanceProfile__basic-info-item">
              <p className="AttendanceProfile__basic-info-label">
                {t("jobs.attendanceProfile.basicInfo.email")}
              </p>
              <p className="AttendanceProfile__basic-info-value">
                {person?.email
                  ? person?.email
                  : t(
                      "jobs.attendanceProfile.basicInfo.emailNoData",
                    )}
              </p>
            </div>
            <div className="AttendanceProfile__basic-info-item">
              <p className="AttendanceProfile__basic-info-label">
                {t("jobs.attendanceProfile.basicInfo.personalNumber")}
              </p>
              <p className="AttendanceProfile__basic-info-value">
                {person?.personal_number}
              </p>
            </div>
          </div>
          {!isLongTerm && (
          <div className="AttendanceProfile__button-wrapper">
            <Button
              label={t("jobs.attendanceProfile.downloadContract")}
              onClick={() => handleClick()}
              className="Button__success AttendanceProfile__button-download"
            />
          </div>
        )}
        </div>
      )}

      {openReviewModal && (
          <ReviewModal
              onCancel={() => setOpenReviewModal(false)}
              jobId={jobId}
              personId={person.id}
              setGetReview={setGetReview}
              person={person}
              setPerson={setPerson}
          />
      )}

      {openComplete && (
          <WarningModal
              title={t("jobs.attendanceProfile.completeTitle")}
              buttonLabel={t("jobs.attendanceProfile.complete")}
              onCancel={() => setOpenComplete(false)}
              onSubmit={() => {
                handleComplete();
                setOpenComplete(false);
              }}
          />
      )}
      {openUnAttend && (
          <WarningModal
              title={t("jobs.attendanceProfile.didNotAttendTitle")}
          buttonLabel={t("jobs.attendanceProfile.didNotAttend")}
          onCancel={() => setOpenUnAttend(false)}
          onSubmit={() => {
            handleDidNotAttend();
            setOpenUnAttend(false);
          }}
        />
      )}
      {openContactModal && (
        <ContactModal
          phoneNumber={person?.phone_number}
          onClose={() => setOpenContactModal(false)}
        />
      )}
    </section>
  );
};

export default AttendanceProfile;
