import React, { useState } from "react";
import Button from "../Button/Button";
import closeIcon from "../../../assets/svg/close-dark.svg";
import cs from "classnames";
import TextAreaInput from "../TextAreaInput/TextAreaInput";
import { useFormik } from "formik";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import { useTranslation } from "react-i18next";
import ModalContainer from "../ModalContainer/ModalContainer";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";

import "./CommentModal.scss";
import { apiCall } from "../../../api/Api";

const CommentModal = (props: any) => {
  const { comment, applicantId} = props;
  const [isLoading, setIsLoading] = useState(false);
  const globalCtx = useGlobalContext();
  const { t } = useTranslation("common");

  const validate = (values: any) => {
    const errors: any = {};
    if (values.comment.length > 5000) {
      errors.comment = t("commentValidate");
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      comment: comment || ""
    },
    validate,
    onSubmit: (values) => {
      setIsLoading(true);
      const data = {
        comment: values.comment,
      };

      apiCall("POST", `b2b/applications/${applicantId}/comment/`, data)
        .then((res) => {
          setIsLoading(false);

          globalCtx.openMessageModal(
            "success",
            t("commentAddedSuccess"),
          );
          props.onSubmit(data.comment);
          props.onCancel();
          // setGetReview(true);
        })
        .catch((err) => {
          setIsLoading(true);
          const errorMessage = convertRestApiErrorCodes(
            err?.response?.data?.code,
            t,
          );
          globalCtx.openMessageModal("error", errorMessage);
          props.onCancel();
        });
    },
  });

  return (
    <ModalContainer position="center">
      <div className="CommentModal__container">
        <div className="CommentModal__header">
          <h2 className="CommentModal__label">{t("addComment")}</h2>
          <img
            className="CommentModal__close"
            alt="close icon"
            src={closeIcon}
            onClick={() => {
              props.onCancel();
            }}
          />
        </div>

        <div className="CommentModal__text-area-container">
          <div className="CommentModal__text-area-label">
            {t("applicantWillNotSee")}
          </div>
          <TextAreaInput
            name="comment"
            value={formik.values.comment}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            className="CommentModal__textarea"
          />
          {typeof formik.errors.comment === "string" && (
            <div className="CommentModal__error">{formik.errors.comment}</div>
          )}
        </div>

        <div className="CommentModal__buttons">
          <Button
            type="submit"
            style={{ height: "71px", width: "207px" }}
            label={t("submit")}
            onClick={() => {
              formik.handleSubmit();
            }}
            disabled={
              formik.values.comment.length > 5000
            }
            className="CommentModal__submit-btn"
            isLoading={isLoading}
          />
        </div>
      </div>
    </ModalContainer>
  );
};

export default CommentModal;
