import React, { useEffect, useState } from "react";

import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import { apiCall } from "../../../api/Api";
import { useTranslation } from "react-i18next";
import { useEditProfileFormik } from "./useEditProfile";
import { useNavigate } from "react-router-dom";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";
import userAvatar from "../../../assets/images/user-avatar.png";
import penLogo from "../../../assets/svg/pen-logo.svg";
import deleteIcon from "../../../assets/svg/delete-icon.svg";
import chevronDown from "../../../assets/svg/chevron-down-gray.svg";
import FileUploader from "../../../components/shared/FileUploader/FileUploader";
import InputField from "../../../components/shared/InputField/InputField";
import Button from "../../../components/shared/Button/Button";
import ModalContainer from "../../../components/shared/ModalContainer/ModalContainer";
import SwitchButton from "../../../components/shared/SwitchButton/SwitchButton";
import LanguageDropdown from "../../../components/shared/LanguageDropdown/LanguageDropdown";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";

import "./EditProfileSettings.scss";

const EditProfileSettings = () => {
  const [profileData, setProfileData] = useState<any | null>();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isDeleteButtonLoading, setDeleteIsButtonLoading] = useState(false);
  const [isImageUploaderOpen, setIsImageUploaderOpen] = useState(false);
  const [base64Image, setBase64Image] = useState<null | string>(null);
  const [base64ImageError, setBase64ImageError] = useState(false);
  const [isAppEmailNotificationsChecked, setIsAppEmailNotificationsChecked] =
    useState(false);
  const [isAppPushNotificationsChecked, setIsAppPushNotificationsChecked] =
    useState(false);
  const [isJobEmailNotificationsChecked, setIsJobEmailNotificationsChecked] =
    useState(false);
  const [isJobPushNotificationsChecked, setIsJobPushNotificationsChecked] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { t } = useTranslation("common");
  const globalCtx = useGlobalContext();
  const navigate = useNavigate();

  useEffect(() => {
    apiCall("GET", "b2b/user/profile/")
      .then((response) => {
        setProfileData(response.data);
      })
      .catch((error) => {
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
        setProfileData(null);
      });
  }, []);

  const formik = useEditProfileFormik({
    onSubmit: async (values: any) => {
      try {
        setIsButtonLoading(true);
        const data = {
          display_name: values.displayName,
          language: globalCtx.selectedLanguage.keyword,
          ...(base64Image?.startsWith("data:image/") && {
            picture_data: base64Image,
          }),
          allow_application_email_notifications: isAppEmailNotificationsChecked,
          allow_application_push_notifications: isAppPushNotificationsChecked,
          allow_job_email_notifications: isJobEmailNotificationsChecked,
          allow_job_push_notifications: isJobPushNotificationsChecked,
        };

        // UPDATE PROFILE
        await apiCall("POST", "b2b/user/profile/", data);

        // GET USER PROFILE
        const profileData = await apiCall("GET", "b2b/user/profile/");

        // UPDATE LOCAL STORAGE
        localStorage.setItem(
          "profile",
          JSON.stringify(profileData.data.profile),
        );
        globalCtx.openMessageModal(
          "success",
          t("staticMessage.profileUpdatedSuccess"),
        );

        // PROCEED
        setTimeout(() => {
          navigate("/profile/profile-settings");
        }, 3000);
      } catch (error: any) {
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
      } finally {
        setIsButtonLoading(false);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue(
      "displayName",
      profileData?.profile.display_name || "",
    );
    setBase64Image(profileData?.profile.picture_url);
    setIsAppEmailNotificationsChecked(
      profileData?.profile.allow_application_email_notifications,
    );
    setIsAppPushNotificationsChecked(
      profileData?.profile.allow_application_push_notifications,
    );
    setIsJobEmailNotificationsChecked(
      profileData?.profile.allow_job_email_notifications,
    );
    setIsJobPushNotificationsChecked(
      profileData?.profile.allow_job_push_notifications,
    );
  }, [profileData]);

  const handleDeleteProfilePicture = () => {
    setDeleteIsButtonLoading(true);
    apiCall("DELETE", "b2b/user/delete-profile-picture/")
      .then((response) => {
        // UPDATE LOCAL STORAGE
        localStorage.setItem("profile", JSON.stringify(response.data));
        globalCtx.openMessageModal(
          "success",
          t("staticMessage.profileImageDeletedSuccess"),
        );
        setBase64Image(null);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
      })
      .finally(() => {
        setDeleteIsButtonLoading(false);
        setIsDeleteModalOpen(false);
      });
  };

  return (
    <>
      {isImageUploaderOpen && (
        <ModalContainer>
          <FileUploader
            maxFileSizeInBytes={5242880}
            accept="image/*"
            onCancel={() => {
              setIsImageUploaderOpen(false);
            }}
            onSubmit={(fileName, base64) => {
              if (base64 !== undefined) {
                setBase64Image(base64);
                setIsImageUploaderOpen(false);
                setBase64ImageError(false);
              }
            }}
          />
        </ModalContainer>
      )}
      {isDeleteModalOpen && (
        <ModalContainer>
          <div className="DeleteCardModal">
            <p className="DeleteCardModal__question">
              {t("profile.editProfileSettings.deleteModal.question")}
            </p>
            <div className="DeleteCardModal__buttons">
              <Button
                className="DeleteCardModal__buttons-button Button__secondary"
                onClick={() => {
                  setIsDeleteModalOpen(false);
                }}
                label={t("profile.editProfileSettings.deleteModal.cancel")}
              />
              <Button
                isLoading={isDeleteButtonLoading}
                className="DeleteCardModal__buttons-button Button__error"
                onClick={() => {
                  handleDeleteProfilePicture();
                }}
                label={t("profile.editProfileSettings.deleteModal.delete")}
              />
            </div>
          </div>
        </ModalContainer>
      )}

      <div className="EditProfileSettingsContainer">
        <div className="EditProfileSettingsContainer__head">
          <p className="EditProfileSettingsContainer__head-title">
            {t("profile.title")}
          </p>
        </div>
        <div className="EditProfileSettingsContainer__body">
          {profileData === undefined ? (
            <div className="EditProfileSettings__loader">
              <LoadingComponent />
            </div>
          ) : profileData === null ? (
            <div className="EditProfileSettings__noData">{t("noData")}</div>
          ) : (
            <div className="EditProfileSettings">
              <div className="EditProfileSettings__head">
                <p className="EditProfileSettings__title">
                  <img
                    alt="pic"
                    className="EditProfileSettings__title-back"
                    src={chevronDown}
                    onClick={() => {
                      navigate("/profile/profile-settings");
                    }}
                  />
                  {t("profile.editProfileSettings.title")}
                </p>
              </div>

              <div className="EditProfileSettings__body">
                <div className="EditProfileSettings__form">
                  <div className="EditProfileSettings__uploader">
                    <div className="EditProfileSettings__circle">
                      {base64Image === null ? (
                        <img
                          alt="pic"
                          src={userAvatar}
                          className="EditProfileSettings__placeholder"
                        />
                      ) : (
                        <img
                          alt="pic"
                          src={base64Image}
                          className="EditProfileSettings__image"
                        />
                      )}

                      <img
                        alt="pic"
                        src={base64Image === null ? penLogo : deleteIcon}
                        className="EditProfileSettings__pen"
                        onClick={() => {
                          base64Image === null
                            ? setIsImageUploaderOpen(true)
                            : setIsDeleteModalOpen(true);
                        }}
                      />
                    </div>
                    {base64ImageError && (
                      <h5 className="EditProfileSettings__uploader-error">
                        {t("profile.editProfileSettings.profilePictureError")}
                      </h5>
                    )}
                  </div>
                  <div className="EditProfileSettings__inputs">
                    <div className="EditProfileSettings__input">
                      <InputField
                        type="text"
                        placeholder={t("profile.profileSettings.fillDisplayName")}
                        name="displayName"
                        value={formik.values.displayName}
                        onBlur={(e: any) => formik.handleBlur(e)}
                        onChange={(e: any) => formik.handleChange(e)}
                      />
                      {formik.errors.displayName &&
                        formik.touched.displayName && (
                          <h5 className="EditProfileSettings__input-error">
                            {formik.errors.displayName as string}
                          </h5>
                        )}
                    </div>{" "}
                    <div className="EditProfileSettings__input">
                      <InputField
                        disabled={true}
                        type="text"
                        placeholder="Email"
                        name="email"
                        value={profileData?.user.email}
                        onBlur={(e: any) => {}}
                        onChange={(e: any) => {}}
                      />
                    </div>{" "}
                    <div className="EditProfileSettings__switch">
                      <h5 className="EditProfileSettings__switch-label">
                        {" "}
                        {t("profile.editProfileSettings.language")}
                      </h5>
                      <LanguageDropdown />
                    </div>{" "}
                    <div className="EditProfileSettings__switch">
                      <h5 className="EditProfileSettings__switch-label">
                        {t(
                          "profile.editProfileSettings.applicationEmailNotifications",
                        )}
                      </h5>
                      <SwitchButton
                        isChecked={isAppEmailNotificationsChecked}
                        onSwitchClick={() => {
                          setIsAppEmailNotificationsChecked(
                            !isAppEmailNotificationsChecked,
                          );
                        }}
                      />
                    </div>
                    <div className="EditProfileSettings__switch">
                      <h5 className="EditProfileSettings__switch-label">
                        {t(
                          "profile.editProfileSettings.applicationPushNotifications",
                        )}
                      </h5>
                      <SwitchButton
                        isChecked={isAppPushNotificationsChecked}
                        onSwitchClick={() => {
                          setIsAppPushNotificationsChecked(
                            !isAppPushNotificationsChecked,
                          );
                        }}
                      />
                    </div>
                    <div className="EditProfileSettings__switch">
                      <h5 className="EditProfileSettings__switch-label">
                        {t("profile.editProfileSettings.jobEmailNotifications")}
                      </h5>
                      <SwitchButton
                        isChecked={isJobEmailNotificationsChecked}
                        onSwitchClick={() => {
                          setIsJobEmailNotificationsChecked(
                            !isJobEmailNotificationsChecked,
                          );
                        }}
                      />
                    </div>
                    <div className="EditProfileSettings__switch">
                      <h5 className="EditProfileSettings__switch-label">
                        {t("profile.editProfileSettings.jobPushNotifications")}
                      </h5>
                      <SwitchButton
                        isChecked={isJobPushNotificationsChecked}
                        onSwitchClick={() => {
                          setIsJobPushNotificationsChecked(
                            !isJobPushNotificationsChecked,
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="EditProfileSettings__footer">
                    <Button
                      className="EditProfileSettings__button"
                      type="submit"
                      isLoading={isButtonLoading}
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                      label={t("profile.editProfileSettings.button")}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditProfileSettings;
