import { useLocation } from "react-router-dom";

export const useGetParameterByName = (name: string) => {
  const location = useLocation();
  let searchParams = new URLSearchParams(location.search);

  let nameValue = searchParams.get(name);

  return nameValue ? nameValue : "";
};

export default useGetParameterByName;
