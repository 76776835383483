import React, { useEffect, useState } from "react";
import { apiCall } from "../../../api/Api";
import ShortTermJobCard from "../ShortTermJobCard/ShortTermJobCard";
import useInfiniteScroll from "react-infinite-scroll-hook";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../../assets/svg/plus-icon-white.svg";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import reloadIcon2 from "../../../assets/svg/Refresh_icon.svg";

import "./LongTermAssigned.scss";
import LongTermJobCard from "../LongTermJobCard/LongTermJobCard";
import UnitSelectBox from "../../../components/shared/SelectBox/UnitSelectBox";

interface FilteredJobs {
  [key: string]: [];
}

const LongTermAssigned = () => {
  const [assignedJobs, setAssignedJobs] = useState<any>([]);
  const [nextPage, setNextPage] = useState<any>("");
  const [timeDates, setTimeDates] = useState<any>();
  const [isCalled, setIsCalled] = useState(false);
  const [loading] = useState(false);
  const [error] = useState("");
  const [sortedKeys, setSortedKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const daysOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const [jobStatus, setJobStatus] = useState("Assigned");
  const [jobsCountLoading, setJobsCountLoading] = useState(false);
  const [notAssignedJobsCount, setNotAssignedJobsCount] = useState(0);
  const [assignedJobsCount, setAssignedJobsCount] = useState(0);
  const [refreshCalled, setRefreshCalled] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [businessUnits, setBusinessUnits] = useState<[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<any>({});

  useEffect(() => {
    callJobs();
  }, [selectedUnit]);


  useEffect(() => {
    try {
      apiCall("get", "b2b/organization-unit/").then((res: any) => {
        setBusinessUnits(res.data);
      });
    } catch (err) {
    }
  }, []);

  useEffect(() => {
    setJobsCountLoading(true);
    apiCall("GET", `b2b/long-term-jobs/?status=assigned&limit=0`)
      .then((res) => {
        setAssignedJobsCount(res.data.count);
      })
      .finally(() => {
        setJobsCountLoading(false);
      });
    apiCall("GET", `b2b/long-term-jobs/?status=available&limit=0`)
      .then((res) => {
        setNotAssignedJobsCount(res.data.count);
      })
      .finally(() => {
        setJobsCountLoading(false);
      });
  }, []);

  const filterDates = (datesArray: any) => {
    //groups jobs into same dates
    const filteredJobs: FilteredJobs = datesArray.reduce(
      (group: any, item: any) => {
        const date = new Date(item?.job_start_time);
        const isoDateKey = date.toISOString().split("T")[0];
        group[isoDateKey] = group[isoDateKey] ?? [];
        group[isoDateKey].push(item);

        return group;
      },
      {},
    );

    setTimeDates(filteredJobs);

    const sortedKeys2: any = Object.keys(filteredJobs).sort();
    setSortedKeys(sortedKeys2);
  };

  const callJobs = () => {
    setIsLoading(true);

    const url = `b2b/long-term-jobs/?status=assigned&limit=10${
      selectedUnit?.id ? `&organization_unit=${selectedUnit.id}` : ""
    }`;

    apiCall("GET", url)
      .then((res) => {
        console.log("Long Term Assigned: ", res);
        setAssignedJobs(res.data.results);
        filterDates(res.data.results);
        setIsCalled(true);
        setNextPage(res.data?.next);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!isCalled) {
      callJobs();
    }
  }, []);

  const loadMoreJobs = () => {
    if (nextPage !== null && isCalled) {
      setIsLoading(true);
      let resultUrl = nextPage.split("?")[1];
      apiCall("GET", `b2b/long-term-jobs/?${resultUrl}`)
        .then((res) => {
          setAssignedJobs([...assignedJobs, ...res.data.results]);
          filterDates([...assignedJobs, ...res.data.results]);
          setIsCalled(true);
          setIsLoading(false);
          setNextPage(res.data?.next);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: nextPage,
    onLoadMore: loadMoreJobs,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <section className="LongTermAssigned">
      <div className="LongTermAssigned__header-container">
        <div className="LongTermAssigned__title-container">
          <div className="LongTermAssigned__main-title">{t("jobs.title")}</div>
          {isLoading ? (
            <div className="LongTermAssigned__spinner" />
          ) : (
            <img
              src={reloadIcon2}
              alt="reload icon"
              className={cs(
                "LongTermAssigned__reload-icon",
                refreshCalled && "LongTermAssigned__reload-icon--disable",
              )}
              onClick={() => {
                if (!refreshCalled) {
                  callJobs();
                  setRefreshCalled(true);
                  setTimeout(() => {
                    setRefreshCalled(false);
                  }, 10000);
                }
              }}
            />
          )}
          <UnitSelectBox
            items={businessUnits}
            selectedItem={selectedUnit}
            onItemSelect={(item: any) => {
              setSelectedUnit(item); // Set selected unit only
            }}
            placeholder={t("jobs.newShortTermJob.jobDetails.businessUnit.placeholder")}
            onClick={() => {}}
            onBlur={(e: any) => {}}
            onChange={(e: any) => {}}
            onAllUnitsClicked={() => {
              setSelectedUnit({}); // Clear selected unit for "All" option
            }}
          />
        </div>
        <div className="LongTermAssigned__button-wrapper">
          <div
            className="LongTermAssigned__button"
            onClick={() => {
              navigate("/jobs/new-job");
            }}
          >
            <div className="LongTermAssigned__button-round">
              <img
                alt="pic"
                src={plusIcon}
                className="LongTermAssigned__button-round--plus"
              />
            </div>
            <p className="LongTermAssigned__button-text">
              {t("jobs.addJobButton")}
            </p>
          </div>
        </div>
      </div>
      <section className="LongTermAssigned__container">
        <div
          onClick={() => {
            navigate("/jobs/short-term-not-assigned");
          }}
          className={cs("LongTermAssigned__container-item")}
        >
          {t("jobs.shortTermTabLabel")}
        </div>
        <div
          onClick={() => {
            navigate("/jobs/long-term-not-assigned");
          }}
          className={cs(
            "LongTermAssigned__container-item",
            "LongTermAssigned__container-item--active",
          )}
        >
          {t("jobs.longTermTabLabel")}
        </div>
      </section>
      <section className="LongTermAssigned__buttons-container">
        <div
          className={cs("LongTermAssigned__not-assigned-btn flex")}
          onClick={() => {
            navigate("/jobs/long-term-not-assigned");
          }}
        >
          {`${t("jobs.notAssigned.tabLabel")} `}{" "}
          {jobsCountLoading ? (
            <div className="LongTermAssigned__spinner" />
          ) : (
            `(${notAssignedJobsCount})`
          )}
        </div>
        <div
          className={cs(
            "LongTermAssigned__assigned-btn flex",
            "LongTermAssigned__assigned-btn--active",
          )}
          onClick={() => {
            navigate("/jobs/long-term-assigned");
          }}
        >
          {`${t("jobs.assigned.tabLabel")}`}{" "}
          {jobsCountLoading ? (
            <div className="LongTermAssigned__spinner" />
          ) : (
            `(${assignedJobsCount})`
          )}{" "}
        </div>
      </section>
      {assignedJobs?.length === 0 && isCalled && (
        <div className="LongTermAssigned__no-content">
          {t("jobs.assigned.noContent")}
        </div>
      )}

      {timeDates &&
        sortedKeys?.map((key, index) => (
          <div className="LongTermAssigned__timeline" key={index}>
            <div className="LongTermAssigned__timeline-container">
              <div className="LongTermAssigned__timeline-content">
                <div className="LongTermAssigned__timeline-dot">
                  <div className="LongTermAssigned__timeline-dot-weekday">
                    {daysOfTheWeek[new Date(key).getDay()]}
                  </div>
                  <div className="LongTermAssigned__timeline-dot-day">
                    {new Date(key).getDate()}
                  </div>
                </div>
                {timeDates &&
                  timeDates[key]?.map((job: any, index: number) => (
                    <div key={index} className="LongTermAssigned__job-wrapper">
                      {/*<ShortTermJobCard*/}
                      {/*  job={job}*/}
                      {/*  jobStatus={"Upcoming"}*/}
                      {/*  subStatus={jobStatus}*/}
                      {/*  shiftTotal={true}*/}
                      {/*/>*/}
                    <LongTermJobCard job={job} shiftTotal={true} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
      {isLoading && assignedJobs.length === 0 && (
        <div style={{ margin: "150px auto" }}>
          <LoadingComponent />
        </div>
      )}
      <div>
        {nextPage && nextPage !== null && (
          <div ref={sentryRef}>
            <LoadingComponent />
          </div>
        )}
      </div>
    </section>
  );
};

export default LongTermAssigned;
