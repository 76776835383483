import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

export const useEditLongTermJobFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  const { t } = useTranslation("common");

  const memoizedT = useCallback(t, [t]);

  return useFormik({
    initialValues: {
      minSalary: "",
      maxSalary: "",
      seats: "",
      description: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      minSalary: Yup.number().optional(),
      maxSalary: Yup.number().optional(),
      seats: Yup.number().min(1),
      description: Yup.string()
        .min(5, memoizedT("jobs.newLongTermJob.jobDetails.description.min"))
        .max(5000, memoizedT("jobs.newLongTermJob.jobDetails.description.max")),
    }),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
