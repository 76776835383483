import React, { useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";
import ContactModal from "../ContactModal/ContactModal";
import helpIcon from "../../../assets/images/help-icon-thin.png";

import "./HelpDropdown.scss";

interface HelpDropdownProps {
  width?: string;
  height?: string;
}
const HelpDropdown = (props: HelpDropdownProps) => {
  const { width, height } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { t } = useTranslation("common");
  const langFromLocalStorage = localStorage.getItem("lang");

  useOutsideClickDetection(dropdownRef, () => {
    setIsDropdownOpen(false);
  });

  return (
    <>
      <div ref={dropdownRef} className="HelpDropdown">
        <div
          className="HelpDropdown__head"
          onClick={() => {
            setIsDropdownOpen(true);
          }}
        >
          <img
            style={{ width: `${width}px`, height: `${height}px` }}
            alt="pic"
            className="HelpDropdown__image"
            src={helpIcon}
          />

          <h5 className="HelpDropdown__name">{t("helpDropdown.label")}</h5>
        </div>
        {isDropdownOpen && (
          <div className="HelpDropdown__body">
            <h5
              className="HelpDropdown__body-item"
              onClick={() => {
                window.open("https://www.peakjobs.app/en/guide/", "_blank");
                setIsDropdownOpen(false);
              }}
            >
              {t("helpDropdown.guide")}
            </h5>
            <h5
              className="HelpDropdown__body-item"
              onClick={() => {
                window.open(
                  langFromLocalStorage === "EN"
                    ? "https://www.peakjobs.app/en/faq/"
                    : "https://www.peakjobs.app/faq-sq/",
                  "_blank",
                );
                setIsDropdownOpen(false);
              }}
            >
              {t("helpDropdown.faq")}
            </h5>
            <h5
              className="HelpDropdown__body-item"
              onClick={() => {
                window.open(
                  langFromLocalStorage === "EN"
                    ? "https://www.peakjobs.app/en/tips/"
                    : "https://www.peakjobs.app/tips-sq/",
                  "_blank",
                );
                setIsDropdownOpen(false);
              }}
            >
              {t("helpDropdown.tips")}
            </h5>{" "}
          </div>
        )}
      </div>
    </>
  );
};

export default HelpDropdown;
