import React, { useState } from "react";

import { auth } from "../../../firebaseConfig";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useSignUpFormik } from "./useSignUpFormik";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import { apiCall, apiCallWithoutAuth } from "../../../api/Api";
import { useNavigate } from "react-router-dom";
import { handleErrors } from "../../../lib/helpers/handleErrors";
import InputField from "../../../components/shared/InputField/InputField";
import Button from "../../../components/shared/Button/Button";
import Checkbox from "../../../components/shared/Checkbox/Checkbox";
import HelpDropdown from "../../../components/shared/HelpDropdown/HelpDropdown";
import eye from "../../../assets/svg/eye.svg";
import eyeSlash from "../../../assets/svg/eye-slash.svg";
import googleIcon from "../../../assets/images/google-icon.png";

import "./SignUpForm.scss";

const SignUpForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [checkBoxError, setCheckboxError] = useState(false);
  const { t } = useTranslation("common");
  const globalCtx = useGlobalContext();
  const authCtx = useAuthContext();
  const navigate = useNavigate();
  const isMobile = useResponsiveDimensions().isMobile;
  const langFromLocalStorage = localStorage.getItem("lang");
  const provider = new GoogleAuthProvider();

  const formik = useSignUpFormik({
    onSubmit: async (values: any) => {
      if (isChecked) {
        try {
          setIsLoading(true);
          //CREATE USER
          const userCredential = await createUserWithEmailAndPassword(
            auth,
            values.email,
            values.confirmPassword,
          );
          //VERIFY EMAIL
          await apiCallWithoutAuth("post", "b2b/user/verify-email/", {
            email: userCredential.user.email,
          });
          //PROCEED
          navigate("/send-email");
        } catch (error: any) {
          //CHECK IF FIREBASE ERROR OR API ERROR
          const errorMessage = handleErrors(error, t);
          globalCtx.openMessageModal("error", errorMessage);
          authCtx.signOutFirebaseUser();
        } finally {
          setIsLoading(false);
        }
      } else {
        setCheckboxError(true);
      }
    },
  });

  const handleContinueWithGoogle = async () => {
    try {
      setIsLoadingGoogle(true);
      //SIGN IN WITH GOOGLE
      await signInWithPopup(auth, provider);

      //GET USER PROFILE
      let profileData = await apiCall("GET", "b2b/user/profile/");

      //IF PHONE NUMBER IS NOT VERIFIED
      // if (profileData?.data?.user?.phone_number === null) {
      //   navigate("/verify-phone-number");
      //   return;
      // }

      //IF PROFILE IS NOT CREATED
      if (profileData.data.profile === null) {
        navigate("/create-company");
        return;
      }

      //IF COMPANY IS NOT CREATED
      if (profileData.data.user.organization === null) {
        navigate("/create-company");
        return;
      }

      //CHECK USER LANGUAGE
      if (
        profileData.data.profile.language !== globalCtx.selectedLanguage.keyword
      ) {
        //CHANGE LANGUAGE
        await apiCall("POST", "b2b/user/profile/", {
          language: globalCtx.selectedLanguage.keyword,
        });

        //GET EDITED PROFILE DATA AND REPLACE IT
        profileData = await apiCall("GET", "b2b/user/profile/");
      }

      //GET COMPANY
      const companyData = await apiCall("GET", "b2b/organization/");

      // SAVE PROFILE DATA TO LOCAL STORAGE AND RESET STATE
      authCtx.setDataToLocalStorage(profileData.data.profile, companyData.data);
      //PROCEED
      navigate("/");
    } catch (error: any) {
      const errorMessage = handleErrors(error, t);
      globalCtx.openMessageModal("error", errorMessage);
      authCtx.signOutFirebaseUser();
    } finally {
      setIsLoadingGoogle(false);
    }
  };

  return (
    <form className="SignUpForm" onSubmit={formik.handleSubmit}>
      <div className="SignUpForm__container">
        <div className="SignUpForm__title-container">
          <h2 className="SignUpForm__title">{t("signUp.form.title")}</h2>
          <div className="SignUpForm__help">
            <HelpDropdown width="30" height="30" />
          </div>
        </div>

        <div className="SignUpForm__google">
          <div
            className="SignUpForm__google-container"
            onClick={() => {
              handleContinueWithGoogle();
            }}
          >
            {isLoadingGoogle ? (
              <div className="SignUpForm__google-spinner"></div>
            ) : (
              <>
                <img
                  alt="pic"
                  src={googleIcon}
                  className="SignUpForm__google-icon"
                />
                <div className="SignUpForm__google-text">
                  {t("signIn.form.googleText")} Google
                </div>
              </>
            )}
          </div>
        </div>

        <div className="SignUpForm__or">{t("signIn.form.or")}</div>
        <div className="SignUpForm__input">
          <InputField
            type="text"
            placeholder={t("signUp.form.email.placeholder")}
            name="email"
            value={formik.values.email}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
          />
          {formik.errors.email && formik.touched.email && (
            <h5 className="SignUpForm__input-error">
              {formik.errors.email as string}
            </h5>
          )}
        </div>
        <div className="SignUpForm__input">
          <InputField
            type={showPassword ? "text" : "password"}
            placeholder={t("signUp.form.password.placeholder")}
            name="password"
            value={formik.values.password}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            onIconClick={() => {
              setShowPassword(!showPassword);
            }}
            icon={showPassword ? eyeSlash : eye}
          />

          {formik.errors.password && formik.touched.password && (
            <h5 className="SignUpForm__input-error">
              {formik.errors.password as string}
            </h5>
          )}
        </div>

        <div className="SignUpForm__input">
          <InputField
            type={showConfirmPassword ? "text" : "password"}
            placeholder={t("signUp.form.confirmPassword.placeholder")}
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            onIconClick={() => {
              setShowConfirmPassword(!showConfirmPassword);
            }}
            icon={showConfirmPassword ? eyeSlash : eye}
          />
          <h6 className="SignUpForm__input-description">
            {t("signUp.form.password.matches")}
          </h6>

          {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            <h5 className="SignUpForm__input-error">
              {formik.errors.confirmPassword as string}
            </h5>
          )}
        </div>
        <div className="SignUpForm__checkbox">
          <div className="SignUpForm__checkbox-container">
            <Checkbox
              isChecked={isChecked}
              onCheckboxClick={() => {
                setIsChecked(!isChecked);
                setCheckboxError(false);
              }}
            />
            <h5 className="SignUpForm__checkbox-text">
              {t("signUp.form.checkbox.partOne")}{" "}
              <span
                onClick={() => {
                  window.open(
                    langFromLocalStorage === "EN"
                      ? "https://www.peakjobs.app/terms-and-conditions/"
                      : "https://www.peakjobs.app/terms-and-conditions-sq/",
                    "_blank",
                  );
                }}
              >
                {t("signUp.form.checkbox.partTwo")}
              </span>{" "}
              {t("signUp.form.checkbox.partThree")}{" "}
              <span
                onClick={() => {
                  window.open(
                    langFromLocalStorage === "EN"
                      ? "https://www.peakjobs.app/privacy-policy/"
                      : "https://www.peakjobs.app/privacy-policy-sq/",
                    "_blank",
                  );
                }}
              >
                {t("signUp.form.checkbox.partFour")}
              </span>
            </h5>
          </div>
        </div>
        {checkBoxError && (
          <h5 className="SignUpForm__checkbox-error">
            {t("signUp.form.checkbox.error")}
          </h5>
        )}
        <div className="SignUpForm__button">
          <Button
            isLoading={isLoading}
            onClick={() => {}}
            label={t("signUp.form.button")}
            type="submit"
          />
        </div>
        <div className="SignUpForm__link">
          <p className="SignUpForm__link-text">
            {t("signUp.form.loginLink.loginLinkOne")}{" "}
            <span
              onClick={() => {
                navigate("/sign-in");
              }}
            >
              {t("signUp.form.loginLink.loginLinkTwo")}
            </span>
          </p>
        </div>
      </div>
    </form>
  );
};

export default SignUpForm;
