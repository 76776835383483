import React from "react";
import {useTranslation} from "react-i18next";
import "./ContactModal.scss";
import closeIcon from "../../../assets/svg/close-dark.svg";
import ErrorIcon from "../../../assets/svg/error-emblem.svg";
import Button from "../Button/Button";

interface ContactModalProps {
  phoneNumber: string;
  onClose: () => void;
}

const ContactModal: React.FC<ContactModalProps> = ({phoneNumber, onClose}) => {
  const {t} = useTranslation("common");

  return (
    <div className="ContactModal">
      <div className="ContactModal__container">
        <img
          className="ContactModal__close"
          alt="close icon"
          src={closeIcon}
          onClick={() => {
            onClose();
          }}
        />
        <img src={ErrorIcon} alt="alert icon" className="ContactModal__icon"/>
        <div className="ContactModal__scrollableContent">
          <div className="SchedulePlanButton">
            <div className="SchedulePlanButton__price_hint">
              {t("contactModal.title")}
            </div>
            <div className="SchedulePlanButton__body">
              <div className="ContactModal__options">
                <div
                  className="SchedulePlanButton__paragraph"
                  onClick={() => window.open(`tel:${phoneNumber}`, "_self")}
                >
                  <i className="fa fa-phone ContactModal__leading-icon" aria-hidden="true"></i>
                  {t("contactModal.phone")}
                </div>
                <div
                  className="SchedulePlanButton__paragraph"
                  onClick={() => window.open(`https://wa.me/${phoneNumber}`, "_blank")}
                >
                  <i className="fa fa-whatsapp ContactModal__leading-icon" aria-hidden="true" style={{color: "#25D366"}}></i>
                  {t("contactModal.whatsapp")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
