import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { apiCall } from "../../../api/Api";
import { useNavigate } from "react-router-dom";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import PaymentCard from "../../../components/shared/PaymentCard/PaymentCard";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import ModalContainer from "../../../components/shared/ModalContainer/ModalContainer";
import AddPaymentCard from "../AddPaymentCard/AddPaymentCard";
import plusIcon from "../../../assets/svg/plus-icon.svg";
import addUnitsCircle from "../../../assets/svg/add-units-circle.svg";
import chevronDown from "../../../assets/svg/chevron-down-gray.svg";

import "./PaymentCards.scss";

const PaymentCards = () => {
  const [paymentMethods, setPaymentMethods] = useState<any | null>();
  const [isAddPaymentCardOpen, setIsAddPaymentCardOpen] = useState(false);
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const globalCtx = useGlobalContext();

  useEffect(() => {
    // GET PAYMENT METHODS
    apiCall("GET", "subscription/payment-methods/")
      .then((response) => {
        setPaymentMethods(response.data);
      })
      .catch((error) => {
        setPaymentMethods(null);
      });
  }, []);

  // Reorder the payment methods array to have the default payment method first
  const reorderedPaymentMethods = paymentMethods?.data
    ? [
        ...paymentMethods.data.filter(
          (item: any) => item.id === paymentMethods.default_payment_method,
        ),
        ...paymentMethods.data.filter(
          (item: any) => item.id !== paymentMethods.default_payment_method,
        ),
      ]
    : [];

  return (
    <>
      {isAddPaymentCardOpen && (
        <ModalContainer position="top">
          <AddPaymentCard
            onCloseClick={() => {
              setIsAddPaymentCardOpen(false);
            }}
          />
        </ModalContainer>
      )}
      <div className="PaymentCardsContainer">
        <div className="PaymentCardsContainer__head">
          <p className="PaymentCardsContainer__head-title">
            {t("payments.title")}
          </p>
        </div>
        <div className="PaymentCardsContainer__body">
          <div className="PaymentCards">
            <div className="PaymentCards__header">
              <p className="PaymentCards__title">
                <img
                  alt="pic"
                  className="PaymentCards__title-back"
                  src={chevronDown}
                  onClick={() => {
                    navigate("/payments");
                  }}
                />
                {t("payments.paymentCards.title")}
              </p>
            </div>
            <div className="AddPaymentCardCart">
              <div className="AddPaymentCardCart__left">
                <p className="AddPaymentCardCart__title">
                  {t("payments.paymentCards.addPaymentCardCart.title")}
                </p>
                <p className="AddPaymentCardCart__subtitle">
                  {t("payments.paymentCards.addPaymentCardCart.subtitle")}
                </p>
              </div>
              <div
                className="AddPaymentCardCart__button"
                onClick={() => {
                  setIsAddPaymentCardOpen(true);
                }}
              >
                <div className="AddPaymentCardCart__button-round">
                  <img
                    alt="pic"
                    src={plusIcon}
                    className="AddPaymentCardCart__button-round--plus"
                  />
                </div>
                <p className="AddPaymentCardCart__button-text">
                  {" "}
                  {t("payments.paymentCards.addPaymentCardCart.button")}
                </p>
              </div>

              <img
                alt="pic"
                src={addUnitsCircle}
                className="AddPaymentCardCart__circle"
              />
            </div>

            {paymentMethods === undefined ? (
              <div className="PaymentCards__loader">
                <LoadingComponent />
              </div>
            ) : paymentMethods === null ? (
              <div className="PaymentCards__noData">{t("noData")}</div>
            ) : (
              <div className="PaymentCards__carts">
                {reorderedPaymentMethods?.map((item: any, index: number) => (
                  <PaymentCard
                    key={index}
                    item={item}
                    defaultPaymentMethod={paymentMethods.default_payment_method}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentCards;
