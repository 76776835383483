import React, {useState, useEffect} from "react";
import chevronLeft from "../../../assets/svg/chevron-down-gray.svg";
import InputField from "../../../components/shared/InputField/InputField";
import {useLongTermFormik} from "./useLongTermFormik";
import TextAreaInput from "../../../components/shared/TextAreaInput/TextAreaInput";
import closeGray from "../../../assets/svg/close-gray.svg";
import Button from "../../../components/shared/Button/Button";
import info from "../../../assets/svg/info.svg";
import SelectBox from "../../../components/shared/SelectBox/SelectBox";
import DropdownSearch from "../../../components/shared/DropdownSearch/DropdownSearch";
import MultiFileUploader from "../../../components/shared/MultiFileUploader/MultiFileUploader";
import {useGlobalContext} from "../../../context/GlobalContext/GlobalContext";
import deleteIcon from "../../../assets/svg/delete-icon.svg";
import {useTranslation} from "react-i18next";
import {apiCall} from "../../../api/Api";
import {useNavigate} from "react-router-dom";
import {useDebounce} from "use-debounce";
import {PhotoProvider, PhotoView} from "react-image-previewer";
import {
  SlideArrowLeft,
  SlideArrowRight,
  CloseButton,
} from "react-image-previewer/ui";
import {convertRestApiErrorCodes} from "../../../lib/helpers/handleErrors";
import JobPayment from "../../../components/shared/JobPayment/JobPayment";
import ModalContainer from "../../../components/shared/ModalContainer/ModalContainer";
import LongTermPicker from "../../../components/shared/LongTermPicker/LongTermPicker";
import fullTimeIcon from "../../../assets/svg/full-time.svg";
import rotativeIcon from "../../../assets/svg/rotative.svg";
import partTimeMorningIcon from "../../../assets/svg/part-time-morning.svg";
import partTimeAfternoonIcon from "../../../assets/svg/part-time-afternoon.svg";
import partTimeNightIcon from "../../../assets/svg/part-time-night.svg";
import cs from "classnames";

import "./LongTermForm.scss";
import calendarIconLong from "../../../assets/svg/calendar-icon-full.svg";
import plusIcon from "../../../assets/svg/plus-icon-gray.svg";
import WarningModal from "../../../components/shared/WarningModal/WarningModal";
import ConfirmJobPaymentModal from "../../../components/shared/JobPayment/ConfirmJobPayment";
import NewUnitModal from "../../../components/shared/NewUnitModal/NewUnitModal";

interface LongTermPickerDataType {
  job_start_time: string;
  schedule: {
    start_time: string;
    end_time: string;
    shift_days: number[];
    length_in_months: number;
  };
}

const LongTermForm = () => {
  const {t} = useTranslation("common");
  let selectedLanguage = localStorage.getItem("lang");
  const [selectedUnit, setSelectedUnit] = useState<any>({
    name: "",
    value: "",
  });

  const [jobTitles, setJobTitles] = useState<any>();
  const [businessUnits, setBusinessUnits] = useState<[]>([]);
  const [selectError, setSelectError] = useState({
    name: "",
    active: false,
  });
  const [isImageUploaderOpen, setIsImageUploaderOpen] = useState(false);
  const [base64Images, setBase64Images] = useState<File[]>([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffSet] = useState(0);
  const [nextPage, setNextPage] = useState<any>("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [filter, setFilter] = useState<string>("");
  const [debouncedFilter] = useDebounce(filter, 500);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showJobPayment, setShowJobPayment] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [benefits, setBenefits] = useState<null | []>(null);
  const [benefitsLimit, setBenefitsLimit] = useState(5);
  const [selectedBenefits, setSelectedBenefits] = useState<string[]>([]);
  const [benefitsCounts, setBenefitsCounts] = useState(0);
  const [selectedJobType, setSelectedJobType] = useState<string[]>([]);
  const [longTermPickerData, setLongTermPickerData] =
    useState<null | LongTermPickerDataType>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [openNewUnitModal, setOpenNewUnitModal] = useState(false);

  const [showDurationRequiredError, setShowDurationRequiredError] = useState(false);
  const [showJobTypeRequiredError, setShowJobTypeRequiredError] = useState(false);

  const globalCtx = useGlobalContext();
  const navigate = useNavigate();

  const jobTypeItems = [
    {
      label: t("jobs.newLongTermJob.jobType.fullTime"),
      value: "full_time",
      icon: fullTimeIcon,
    },
    {
      label: t("jobs.newLongTermJob.jobType.rotative"),
      value: "rotative",
      icon: rotativeIcon,
    },
    {
      label: t("jobs.newLongTermJob.jobType.partTimeMorning"),
      value: "part_time_morning",
      icon: partTimeMorningIcon,
    },
    {
      label: t("jobs.newLongTermJob.jobType.partTimeAfternoon"),
      value: "part_time_afternoon",
      icon: partTimeAfternoonIcon,
    },
    {
      label: t("jobs.newLongTermJob.jobType.partTimeEvening"),
      value: "part_time_night",
      icon: partTimeNightIcon,
    },
  ];

  useEffect(() => {
    try {
      apiCall("get", "b2b/organization-unit/").then((res) => {
        setBusinessUnits(res.data);
      });
    } catch (err) {
    }
  }, []);

  const refreshUnits = () => {
    try {
      apiCall("get", "b2b/organization-unit/").then((res) => {
        setBusinessUnits(res.data);
      });
    } catch (err) {
    }
  }

  const formik = useLongTermFormik({
    onSubmit: async (values: any) => {
      setIsButtonLoading(true);

      let data = {
        job_title_text: values.job_title_text,
        // job_title: filterItem ? filterItem[0]?.id : "",
        description: values.description,
        organization_unit: selectedUnit?.id,
        image_data: base64Images,
        latitude: selectedUnit?.latitude,
        longitude: selectedUnit?.longitude,
        seats: values.seats,
        ...(selectedBenefits.length > 0 && {benefits: selectedBenefits}),
        ...(longTermPickerData?.job_start_time && {
          job_start_time: longTermPickerData?.job_start_time,
        }),

        schedule: {
          ...(longTermPickerData?.schedule.start_time !== "" && {
            start_time: longTermPickerData?.schedule.start_time,
          }),
          ...(longTermPickerData?.schedule.end_time !== "" && {
            end_time: longTermPickerData?.schedule.end_time,
          }),
          ...(longTermPickerData?.schedule.shift_days &&
            longTermPickerData?.schedule.shift_days.length > 0 && {
              shift_days: longTermPickerData?.schedule.shift_days,
            }),
          ...(formik.values.minSalary !== "" && {
            monthly_pay_rate_start: formik.values.minSalary,
          }),
          ...(formik.values.maxSalary !== "" && {
            monthly_pay_rate_end: formik.values.maxSalary,
          }),
          type: selectedJobType,
          length_in_months: longTermPickerData?.schedule.length_in_months,
        },
      };

      apiCall("POST", "b2b/long-term-jobs/", data)
        .then((res) => {
          // const clientSecret = res.data.checkout_session.client_secret;
          // setClientSecret(clientSecret); // Set the client secret for the payment
          // setShowJobPayment(true); // Show the JobPayment component
          globalCtx.openMessageModal(
            "success",
            t("staticMessage.newJobPostedSuccess"),
          );
          navigate("/jobs/long-term-not-assigned");
          setIsButtonLoading(false);
        })
        .catch((err) => {
          const errorMessage = convertRestApiErrorCodes(
            err?.response?.data?.code,
            t,
          );
          globalCtx.openMessageModal("error", errorMessage);
        })
        .finally(() => {
          setIsButtonLoading(false);
        });
    },
  });

  const handlePostButtonClicked = () => {
    if (
      !!formik.errors.job_title_text ||
      longTermPickerData?.schedule?.length_in_months === 0 ||
      longTermPickerData === null ||
      !!formik.errors.description ||
      !!formik.errors.seats ||
      !!selectError.active ||
      isButtonLoading ||
      !formik.values.description ||
      !formik.values.seats ||
      selectedUnit?.name.length === 0
    ) {
      //   show all form errors if any
      formik.setTouched({
        job_title_text: true,
        description: true,
        seats: true,
      });
      setSelectError({
        name: "Unit",
        active: selectedUnit?.name.length === 0,
      });
      if (longTermPickerData?.schedule?.length_in_months === 0) {
        setShowDurationRequiredError(true);
      }
      if (selectedJobType.length === 0) {
        setShowJobTypeRequiredError(true);
      }

      return;
    } else {
      setOpenConfirmModal(true);
    }
  }

  useEffect(() => {
    apiCall("GET", `job-benefits/?limit=${benefitsLimit}&offset=0`)
      .then((res) => {
        setBenefits(res.data.results);
        setBenefitsCounts(res.data.count);
      })
      .catch((err) => {
      });
  }, [benefitsLimit]);

  const handleSelectBenefit = (item: any) => {
    if (selectedBenefits?.includes(item.id)) {
      // Remove the benefit if it's already selected
      setSelectedBenefits((prev) =>
        prev.filter((benefitId) => benefitId !== item.id),
      );
    } else {
      // Add the benefit if it's not selected
      setSelectedBenefits((prev) => [...prev, item.id]);
    }
  };

  useEffect(() => {
    console.log("selectedJobType.length", selectedJobType.length);
  }, [selectedJobType]);

  const handleSelectJobType = (item: any) => {
    if (selectedJobType?.includes(item.value)) {
      setSelectedJobType((prev) =>
        prev.filter((jobType) => jobType !== item.value),
      );
    } else {
      setSelectedJobType((prev) => [...prev, item.value]);
    }
    if (selectedJobType.length > 0) {
      setShowJobTypeRequiredError(false);
    }
  };
  const handleIntegerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      formik.handleChange(e);
    }
  };
  return (
    <>
      {showJobPayment && clientSecret && (
        <ModalContainer position="top">
          <JobPayment
            clientSecret={clientSecret}
            onCloseClick={() => {
              setShowJobPayment(false);
              navigate("/jobs/long-term-not-assigned");
              globalCtx.openMessageModal(
                "warning",
                t("staticMessage.newJobPostedButNotPaid"),
              );
            }}
          />
        </ModalContainer>
      )}

      <div className="LongTermForm">
        <div className="LongTermForm__header">
          <h1 className="LongTermForm__default-title">{t("jobs.title")}</h1>
          <div className="LongTermForm__header-wrapper">
            <img
              alt="chevron left icon"
              src={chevronLeft}
              className="LongTermForm__back-icon"
              onClick={() => navigate(-1)}
            />
            <h2 className="LongTermForm__title">
              {t("jobs.newLongTermJob.title")}
            </h2>
          </div>
        </div>
        <div className="LongTermForm__form">
          <div className="LongTermForm__column">
            <div className="LongTermForm__subtitle">
              {t("jobs.newLongTermJob.jobDetails.title")}
            </div>
            <div className="LongTermForm__input-container">
              <InputField
                type="text"
                placeholder={t(
                  "jobs.newLongTermJob.jobDetails.jobTitle.placeholder",
                )}
                name="job_title_text"
                value={formik.values.job_title_text}
                onBlur={(e: any) => formik.handleBlur(e)}
                onChange={(e: any) => formik.handleChange(e)}
                className="LongTermForm__input"
              />
              {formik.errors.job_title_text && formik.touched.job_title_text && (
                <p className="LongTermForm__input-error">
                  {formik.errors.job_title_text as string}
                </p>
              )}
            </div>
            <div className="LongTermForm__input-container-row">
              <div className="LongTermForm__input-container">
                <InputField
                  type="text" // Using text to prevent default browser behavior interfering with validation
                  placeholder={t(
                    "jobs.newLongTermJob.jobDetails.minSalary.placeholder",
                  )}
                  name="minSalary"
                  value={formik.values.minSalary}
                  onBlur={(e) => formik.handleBlur(e)}
                  onChange={handleIntegerChange}
                  className="LongTermForm__input"
                />
                {formik.errors.minSalary && formik.touched.minSalary && (
                  <p className="LongTermForm__input-error">
                    {formik.errors.minSalary as string}
                  </p>
                )}
              </div>
              {" "}
              <div className="LongTermForm__input-container">
                <InputField
                  type="text" // Using text to prevent default browser behavior interfering with validation
                  placeholder={t(
                    "jobs.newLongTermJob.jobDetails.maxSalary.placeholder",
                  )}
                  name="maxSalary"
                  value={formik.values.maxSalary}
                  onBlur={(e: any) => formik.handleBlur(e)}
                  onChange={handleIntegerChange}
                  className="LongTermForm__input"
                />
                {formik.errors.maxSalary && formik.touched.maxSalary && (
                  <p className="LongTermForm__input-error">
                    {formik.errors.maxSalary as string}
                  </p>
                )}

                <div className="LongTermForm__input-hint">
                  <img
                    src={info}
                    alt="info icon"
                    className="LongTermForm__input-hint-icon"
                  />
                  <div className="LongTermForm__input-hint-label">
                    {t("jobs.newLongTermJob.jobDetails.maxSalary.hint")}
                  </div>
                </div>
              </div>
            </div>
            <div className="LongTermForm__input-container">
              <InputField
                type="number"
                placeholder={t(
                  "jobs.newLongTermJob.jobDetails.seats.placeholder",
                )}
                name="seats"
                value={formik.values.seats}
                onBlur={(e: any) => formik.handleBlur(e)}
                onChange={(e: any) => formik.handleChange(e)}
                className="LongTermForm__input"
                min="1"
                max="100"
              />
              {formik.errors.seats && formik.touched.seats && (
                <p className="LongTermForm__input-error">
                  {formik.errors.seats as string}
                </p>
              )}
            </div>
            <div
              className="LongTermForm__input-container"
              onMouseOut={() => {
              }}
            >
              <SelectBox
                items={businessUnits}
                selectedItem={selectedUnit}
                onItemSelect={(item: any) => {
                  console.log("item", item);
                  setSelectedUnit(item);
                  setSelectError({
                    name: "Unit",
                    active: false,
                  });
                }}
                placeholder={t(
                  "jobs.newLongTermJob.jobDetails.businessUnit.placeholder",
                )}
                onClick={() => {
                }}
                onBlur={(e: any) => {
                  if (selectedUnit?.name?.length === 0) {
                    setSelectError({name: "Unit", active: true});
                  }
                }}
                onChange={(e: any) => {
                }}
                onNewUnitClicked={() => {
                  setOpenNewUnitModal(true);
                }}
              />

              {selectError.active && selectError.name === "Unit" && (
                <h5 className="LongTermForm__input-error">
                  {t("jobs.newLongTermJob.jobDetails.businessUnit.error")}
                </h5>
              )}
            </div>
            <div className="LongTermForm__input-container">
              <TextAreaInput
                placeholder={t(
                  "jobs.newLongTermJob.jobDetails.description.placeholder",
                )}
                name="description"
                value={formik.values.description}
                onBlur={(e: any) => formik.handleBlur(e)}
                onChange={(e: any) => formik.handleChange(e)}
                className="LongTermForm__textarea"
              />
              {formik.errors.description && formik.touched.description && (
                <p className="LongTermForm__input-error">
                  {formik.errors.description as string}
                </p>
              )}
            </div>
            <div className="LongTermForm__images-label-wrapper">
              <div className="LongTermForm__images-label">
                {t("jobs.newLongTermJob.jobDetails.images.title")}
              </div>
              <img
                src={info}
                alt="info icon"
                className="LongTermForm__info-icon"
              />
              <span className="LongTermForm__images-note">
                {" "}
                {t("jobs.newLongTermJob.jobDetails.images.optional")}
              </span>
            </div>
            <PhotoProvider
              maskOpacity={0.7}
              overlayRender={(props) => {
                return (
                  <>
                    <SlideArrowLeft {...props} />
                    <SlideArrowRight {...props} />
                    <CloseButton onClick={props.onClose}/>
                  </>
                );
              }}
            >
              <div className="LongTermForm__images">
                {base64Images?.length !== 0 &&
                  base64Images?.map((item: any, index: number) => (
                    <div
                      className="LongTermForm__image-preview-container"
                      key={index}
                    >
                      <PhotoView src={item} key={index}>
                        <img
                          alt="image preview"
                          src={item}
                          className="LongTermForm__image-preview"
                          style={{
                            width: "92px",
                            height: "92px",
                            objectFit: "cover",
                            borderRadius: "6px",
                          }}
                        />
                      </PhotoView>
                      <img
                        alt="delete icon"
                        src={deleteIcon}
                        className="LongTermForm__delete-icon"
                        onClick={() => {
                          const filterItems: any = base64Images?.filter(
                            (image) => image !== item,
                          );
                          setBase64Images(filterItems);
                        }}
                      />
                    </div>
                  ))}
                {base64Images.length < 10 && (
                  <div
                    onClick={() => setIsImageUploaderOpen(true)}
                    className="LongTermForm__images-placeholder"
                  >
                    <img
                      src={closeGray}
                      alt="plus icon"
                      className="LongTermForm__add-icon"
                    />
                  </div>
                )}
              </div>
            </PhotoProvider>
            {isImageUploaderOpen && (
              <MultiFileUploader
                accept="image/*"
                onCancel={() => {
                  setIsImageUploaderOpen(false);
                }}
                onSubmit={(images) => {
                  setBase64Images((prev: any) => [...prev, ...images]);
                  setIsImageUploaderOpen(false);
                }}
                allImages={base64Images}
                maxImageSize={10485760}
              />
            )}
          </div>
          <div className="LongTermForm__column">
            <div className="LongTermForm__shift-label">
              {t("jobs.newLongTermJob.jobSchedule.title")}
            </div>
            <LongTermPicker
              sendBackData={(data) => {
                setLongTermPickerData(data);
              }}
              showDurationRequiredError={showDurationRequiredError}
            />

            <div className="LongTermForm__jobType">
              <div className="LongTermForm__jobType-title-container">
                <div className="LongTermForm__jobType-title">
                  {t("jobs.newLongTermJob.jobType.title")}
                </div>
                <img
                  src={info}
                  alt="info icon"
                  className="LongTermForm__info-icon"
                />
                <span className="LongTermForm__images-note">
                  {t("jobs.newLongTermJob.jobType.hint")}
                </span>
              </div>
              <div className="LongTermForm__jobType-body">
                {jobTypeItems.map((item, index) => (
                  <div
                    className={cs(
                      "JobTypeCart",
                      selectedJobType.includes(item.value) &&
                      "JobTypeCart--active",
                    )}
                    key={index}
                    onClick={() => {
                      handleSelectJobType(item);
                    }}
                  >
                    <img
                      alt="pic"
                      src={item.icon}
                      className="JobTypeCart__icon"
                    />
                    <div className="JobTypeCart__label">{item.label}</div>
                  </div>
                ))}
              </div>
              {showJobTypeRequiredError && selectedJobType.length === 0 && (
                <h5 className="LongTermForm__input-error">{t("jobs.newShortTermJob.jobTypeRequired")}</h5>
              )}
            </div>

            <div className="LongTermForm__jobBenefits">
              <div className="LongTermForm__jobBenefits-title-container">
                <div className="LongTermForm__jobBenefits-title">
                  {" "}
                  {t("jobs.newLongTermJob.jobBenefits.title")}
                </div>
                <img
                  src={info}
                  alt="info icon"
                  className="LongTermForm__info-icon"
                />
                <span className="LongTermForm__images-note">
                  {""}
                  {t("jobs.newLongTermJob.jobDetails.images.optional")}
                </span>
              </div>

              <div className="LongTermForm__jobBenefits-body">
                {benefits?.map((item: any, index: number) => (
                  <div
                    key={index}
                    className={cs(
                      "JobBenefitsCart",
                      selectedBenefits.includes(item.id) &&
                      "JobBenefitsCart--active",
                    )}
                    onClick={() => {
                      handleSelectBenefit(item);
                    }}
                  >
                    <img
                      alt="pic"
                      src={item.icon}
                      className="JobBenefitsCart__icon"
                    />
                    <div className="JobBenefitsCart__label">
                      {selectedLanguage === "SQ"
                        ? item.title.sq
                        : item.title.en}
                    </div>
                  </div>
                ))}
              </div>
              <div className="LongTermForm__jobBenefits-footer">
                {benefitsLimit > 5 && (
                  <div
                    className="LongTermForm__jobBenefits-viewLess"
                    onClick={() => {
                      if (benefitsLimit > 5) {
                        setBenefitsLimit(benefitsLimit - 5);
                      }
                    }}
                  >
                    {t("jobs.newLongTermJob.jobBenefits.viewLess")}
                  </div>
                )}

                {benefitsLimit < benefitsCounts && (
                  <div
                    className="LongTermForm__jobBenefits-viewMore"
                    onClick={() => {
                      if (benefitsLimit < benefitsCounts) {
                        setBenefitsLimit(benefitsLimit + 5);
                      }
                    }}
                  >
                    {t("jobs.newLongTermJob.jobBenefits.viewMore")}
                  </div>
                )}
              </div>
            </div>
            {errorMessage !== "" && (
              <h5 className="LongTermForm__errorMessage">{errorMessage}</h5>
            )}
          </div>
        </div>

        <Button
          label={t("jobs.newLongTermJob.completeButton")}
          onClick={() => {
            // formik.handleSubmit();
            // setOpenConfirmModal(true);
            handlePostButtonClicked();
          }}
          className="LongTermForm__button"
          type="submit"
          isLoading={isButtonLoading}
        />
        {openConfirmModal && (
          <ConfirmJobPaymentModal
            buttonLabel={t("jobs.newLongTermJob.completeButton")}
            title={
              selectedLanguage === "SQ"
                ? "Jeni i sigurt që dëshironi ta postoni këtë punë?"
                : "Are you sure you want to post this job?"
            }
            subTitle={
              selectedLanguage === "SQ"
                ? "Deri në pagesën e punës, puna nuk do të jetë e disponueshme për aplikantët."
                : "Until the job payment, the job will not be available for applicants."
            }
            onCancel={() =>
              setOpenConfirmModal(false)
            }
            onSubmit={() => {
              formik.handleSubmit();
              setOpenConfirmModal(false)
            }}
            isLongTerm={true}
          />
        )}
        {openNewUnitModal && (
          <NewUnitModal
            onClose={() => {
              setOpenNewUnitModal(false);
            }}
            onUnitCreate={(unit) => {
              setSelectedUnit(unit);
              setSelectError({
                name: "Unit",
                active: false,
              });
              refreshUnits();
              setOpenNewUnitModal(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default LongTermForm;
