import React, {useState, useEffect} from "react";
import cs from "classnames";
import {getTimeBetweenDates} from "../../../../lib/helpers/getTimeBetweenDates";
import {useGlobalContext} from "../../../../context/GlobalContext/GlobalContext";
import {useTranslation} from "react-i18next";
import verified from "../../../../assets/svg/verified-icon-green.svg";
import Button from "../../../../components/shared/Button/Button";
import {PhotoProvider, PhotoView} from "react-image-previewer";
import WarningModal from "../../../../components/shared/WarningModal/WarningModal";
import {CloseButton} from "react-image-previewer/ui";
import {convertRestApiErrorCodes} from "../../../../lib/helpers/handleErrors";
import ContactModal from "../../../../components/shared/ContactModal/ContactModal";
import calendarIcon from "../../../../assets/svg/calendar-icon.svg";
import startIcon from "../../../../assets/svg/start-icon.svg";
import endIcon from "../../../../assets/svg/end-shift-icon.svg";
import completedIcon from "../../../../assets/svg/completed.svg";

import "./ApplicantSidebar.scss";
import {apiCall} from "../../../../api/Api";
import addToFavoriteIcon from "../../../../assets/svg/heart-thin.svg";
import isFavouriteIcon from "../../../../assets/svg/heart_icon.svg";
import {convertMinsToHrsMins} from "../../../../lib/helpers/convertMinsToHrsMins";
import {Bookmark, BookmarkAddOutlined, SchoolOutlined} from "@mui/icons-material";

const ApplicantSidebar = (props: any) => {
  const {person, jobName, jobId, shiftTotal} = props;
  let selectedLanguage = localStorage.getItem("lang");
  const [applicantStatus, setApplicantStatus] = useState<string>(
    person?.selected_shifts && person?.selected_shifts.length > 0 ? "Applied Shifts" : "Experience"
  );

  const [experience, setExperience] = useState<any>([]);
  const [jobsWithReview, setJobsWithReview] = useState<any>([]);
  const [showFullReview, setShowFullReview] = useState<any>({
    id: 0,
    active: false,
  });
  const [activeButton, setActiveButton] = useState({
    id: 555,
    active: true,
    name: "all jobs",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [filterJobs, setFilterJobs] = useState<any>([]);
  const [openWarningModal, setOpenWarningModal] = useState({
    cancel: false,
    accept: false,
  });
  const [openContactModal, setOpenContactModal] = useState(false); // State for ContactModal
  const [isFavorite, setIsFavorite] = useState(false);
  const daysOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  useEffect(() => {
    setIsFavorite(person.status === "favourite");
  }, []);

  const toggleFavorite = () => {
    apiCall("POST", `b2b/applications/toggle-favourite/${person.id}/`)
      .then((res) => {
        setIsFavorite(!isFavorite);
        props.loadMore();
      })
      .catch((err) => {
      });
  }

  const globalCtx = useGlobalContext();
  const {t} = useTranslation("common");

  useEffect(() => {
    apiCall("GET", `b2b/applications/applicant/${person.id}/past-experiences/`)
      .then((res) => {
        setExperience(res.data);
      })
      .catch((err) => {
      });
    apiCall(
      "GET",
      `b2b/applications/applicant/${person.id}/jobs-with-review/`,
    ).then((res) => {
      setJobsWithReview(res.data);
      setFilterJobs(res.data);
    });
  }, []);

  const handleAccept = () => {
    setIsLoading(true);
    apiCall("POST", `b2b/applications/${jobId}/accept/${person.id}/`)
      .then((res) => {
        props.loadMore();
        props.onClose();
        globalCtx.openMessageModal(
          "success",
          t("staticMessage.applicantAcceptedSuccess"),
        );
        setIsLoading(false);
        setOpenWarningModal({...openWarningModal, accept: false});
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage = convertRestApiErrorCodes(
          err?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
        props.onClose();
        setOpenWarningModal({...openWarningModal, accept: false});
      });
  };

  const handleCancel = () => {
    setIsLoading(true);
    apiCall("POST", `b2b/applications/${jobId}/cancel/${person.id}/`)
      .then((res) => {
        props.loadMore();
        props.onClose();
        globalCtx.openMessageModal(
          "success",
          t("staticMessage.applicantCanceledSuccess"),
        );
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage = convertRestApiErrorCodes(
          err?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
        props.onClose();
      });
  };

  const applyFilter = (name: string) => {
    if (name === "all jobs") {
      setFilterJobs(jobsWithReview);
    } else {
      let results = jobsWithReview.filter((item: any) => {
        return item.job?.job_title.en === name;
      });
      setFilterJobs(results);
    }
  };

  const filteredJobs = jobsWithReview.reduce((group: any, item: any) => {
    group[item?.job?.job_title?.en] = group[item?.job?.job_title?.en] ?? [];
    group[item?.job?.job_title?.en].push(item);
    return group;
  }, {});

  const top5JobsFilter = Object.values(filteredJobs)
    .sort((a: any, b: any) => b.length - a.length)
    .slice(0, 5);

  return (
    <section className="ApplicantSidebar">
      <div className="ApplicantSidebar__header">
        <div className="ApplicantSidebar__header-container">
          <PhotoProvider
            maskOpacity={0.7}
            overlayRender={(props) => {
              return <CloseButton onClick={props.onClose}/>;
            }}
          >
            <PhotoView src={person.applicant?.profile?.picture_url}>
              <img
                src={person.applicant?.profile?.picture_url}
                alt="applicants image"
                className="ApplicantSidebar__person-image"
              />
            </PhotoView>
          </PhotoProvider>

          <div className="ApplicantSidebar__header-info">
            <div className="ApplicantSidebar__name-container">
              <h2
                className="ApplicantSidebar__name">{`${person.applicant?.profile?.first_name} ${person.applicant?.profile?.last_name}`}</h2>
              {person.applicant?.verified && (
                <img
                  src={verified}
                  alt="verified icon"
                  className="ApplicantSidebar__verified-icon"
                />
              )}
              <div className="ApplicantSidebar__review-wrapper">
                &#9733;
                {`${person.applicant?.profile?.average_rating}(${person.applicant?.profile?.total_reviews}) `}
              </div>
            </div>
            <div className="ApplicantSidebar__role">{jobName}</div>
          </div>
        </div>
        {isFavorite ? (
          <div
            className="ApplicantSidebar__favorite-button"
            onClick={() => {
              toggleFavorite();
            }}
          >
            <Bookmark sx={{fontSize: 15}}/>
            {t("jobs.applicantSidebar.removeFromFavorites")}
          </div>
        ) : (
          <div
            className="ApplicantSidebar__favorite-button"
            onClick={() => {
              toggleFavorite();
            }}
          >
            <BookmarkAddOutlined sx={{fontSize: 15}}/>
            {t("jobs.applicantSidebar.addToFavorites")}
          </div>
        )}
        <div className="ApplicantSidebar__header-stats">
          <div className="ApplicantSidebar__body-job-stat">
            <div className="ApplicantSidebar__body-job-stat-wrap">
              <div className="ApplicantSidebar__stat-label">
                {t("jobs.applicantSidebar.attendance")}
              </div>
              <div className="ApplicantSidebar__stat-number">
                {person.applicant?.profile?.attendance_average_rating}
              </div>
            </div>
            <div className="ApplicantSidebar__stat-line">
              {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                <div
                  key={index}
                  className={cs(`ApplicantSidebar__stat-line-one
                        ${person.applicant?.profile?.attendance_average_rating === 1 && item === 1 && `ApplicantSidebar__stat-line-one--red`}
                        ${
                    person.applicant?.profile
                      ?.attendance_average_rating <= 4 &&
                    person.applicant?.profile?.attendance_average_rating >
                    1 &&
                    item <=
                    person.applicant?.profile
                      ?.attendance_average_rating &&
                    person.applicant?.profile
                      ?.attendance_average_rating !== null &&
                    `ApplicantSidebar__stat-line-one--yellow`
                  }
                        ${person.applicant?.profile?.attendance_average_rating === 5 && `ApplicantSidebar__stat-line-one--green`}
                        `)}
                />
              ))}
            </div>
          </div>
          <div className="ApplicantSidebar__body-job-stat">
            <div className="ApplicantSidebar__body-job-stat-wrap">
              <div className="ApplicantSidebar__stat-label">
                {t("jobs.applicantSidebar.skills")}
              </div>
              <div className="ApplicantSidebar__stat-number">
                {person.applicant?.profile?.skill_average_rating}
              </div>
            </div>
            <div className="ApplicantSidebar__stat-line">
              {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                <div
                  key={index}
                  className={cs(`ApplicantSidebar__stat-line-one
                        ${person.applicant?.profile?.skill_average_rating === 1 && item === 1 && `ApplicantSidebar__stat-line-one--red`}
                        ${
                    person.applicant?.profile?.skill_average_rating <=
                    4 &&
                    person.applicant?.profile?.skill_average_rating > 1 &&
                    item <=
                    person.applicant?.profile?.skill_average_rating &&
                    person.applicant?.profile?.skill_average_rating !==
                    null &&
                    `ApplicantSidebar__stat-line-one--yellow`
                  }
                        ${person.applicant?.profile?.skill_average_rating === 5 && `ApplicantSidebar__stat-line-one--green`}
                        `)}
                />
              ))}
            </div>
          </div>
          <div className="ApplicantSidebar__body-job-stat">
            <div className="ApplicantSidebar__body-job-stat-wrap">
              <div className="ApplicantSidebar__stat-label">
                {t("jobs.applicantSidebar.communication")}
              </div>
              <div className="ApplicantSidebar__stat-number">
                {person.applicant?.profile?.communication_average_rating}
              </div>
            </div>
            <div className="ApplicantSidebar__stat-line">
              {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                <div
                  key={index}
                  className={cs(`ApplicantSidebar__stat-line-one
                        ${person.applicant?.profile?.communication_average_rating === 1 && item === 1 && `ApplicantSidebar__stat-line-one--red`}
                        ${
                    person.applicant?.profile
                      ?.communication_average_rating <= 4 &&
                    person.applicant?.profile
                      ?.communication_average_rating > 1 &&
                    item <=
                    person.applicant?.profile
                      ?.communication_average_rating &&
                    person.applicant?.profile
                      ?.communication_average_rating !== null &&
                    `ApplicantSidebar__stat-line-one--yellow`
                  }
                        ${person.applicant?.profile?.communication_average_rating === 5 && `ApplicantSidebar__stat-line-one--green`}
                        `)}
                />
              ))}
            </div>
          </div>
          <div className="ApplicantSidebar__body-job-stat">
            <div className="ApplicantSidebar__body-job-stat-wrap">
              <div className="ApplicantSidebar__stat-label">
                {t("jobs.applicantSidebar.teamWork")}
              </div>
              <div className="ApplicantSidebar__stat-number">
                {person.applicant?.profile?.teamwork_average_rating}
              </div>
            </div>
            <div className="ApplicantSidebar__stat-line">
              {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                <div
                  key={index}
                  className={cs(`ApplicantSidebar__stat-line-one
                        ${person.applicant?.profile?.teamwork_average_rating === 1 && item === 1 && `ApplicantSidebar__stat-line-one--red`}
                        ${
                    person.applicant?.profile?.teamwork_average_rating <=
                    4 &&
                    person.applicant?.profile?.teamwork_average_rating >
                    1 &&
                    item <=
                    person.applicant?.profile
                      ?.teamwork_average_rating &&
                    person.applicant?.profile?.teamwork_average_rating !==
                    null &&
                    `ApplicantSidebar__stat-line-one--yellow`
                  }
                        ${person.applicant?.profile?.teamwork_average_rating === 5 && `ApplicantSidebar__stat-line-one--green`}
                        `)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <section className="ApplicantSidebar__container">
        {person?.selected_shifts && person?.selected_shifts.length > 0 && (
          <div
            onClick={() => setApplicantStatus("Applied Shifts")}
            className={cs(
              "ApplicantSidebar__container-item",
              applicantStatus === "Applied Shifts" &&
              "ApplicantSidebar__container-item--active",
            )}
          >
            {t("jobs.applicantSidebar.appliedShifts")}
          </div>
        )}

        <div
          onClick={() => setApplicantStatus("Experience")}
          className={cs(
            "ApplicantSidebar__container-item",
            applicantStatus === "Experience" &&
            "ApplicantSidebar__container-item--active",
          )}
        >
          {t("jobs.applicantSidebar.experience.title")}
        </div>
        {/*<div*/}
        {/*  onClick={() => setApplicantStatus("Jobs")}*/}
        {/*  className={cs(*/}
        {/*    "ApplicantSidebar__container-item",*/}
        {/*    applicantStatus === "Jobs" &&*/}
        {/*    "ApplicantSidebar__container-item--active",*/}
        {/*  )}*/}
        {/*>*/}
        {/*  {t("jobs.applicantSidebar.jobs.title")} (*/}
        {/*  {person.applicant?.profile?.total_jobs})*/}
        {/*</div>*/}
        <div
          onClick={() => setApplicantStatus("Personal Details")}
          className={cs(
            "ApplicantSidebar__container-item",
            applicantStatus === "Personal Details" &&
            "ApplicantSidebar__container-item--active",
          )}
        >
          {t("jobs.applicantSidebar.personalDetails.title")}
        </div>
        <div
          onClick={() => setApplicantStatus("Education")}
          className={cs(
            "ApplicantSidebar__container-item",
            applicantStatus === "Education" &&
            "ApplicantSidebar__container-item--active",
          )}
        >
          {t("education")}
        </div>
      </section>
      {applicantStatus === "Applied Shifts" && (
        <section className="ApplicantSidebar__experience">
          {person?.selected_shifts.map((item: any, index: number) => (
            // <div className="ApplicantSidebar__experience-item" key={index}>
            <div className="ShortTermJobCard__shift-card-body">
              <div>
                <div className="ShortTermJobCard__shift-card-start">
                  {" "}
                  {`${daysOfTheWeek[new Date(item.start_time).getDay()]} ${new Date(item.start_time).toLocaleString("en-GB", {
                    month: "short",
                    day: "numeric"
                  })}`}
                  {new Date(item.end_time).toLocaleString("en-GB", {
                    month: "short",
                    day: "numeric",
                  }) !==
                  new Date(item.start_time).toLocaleString("en-GB", {
                    month: "short",
                    day: "numeric",
                  })
                    ? ` - ${daysOfTheWeek[new Date(item.end_time).getDay()]} ${new Date(item.end_time).toLocaleString("en-GB", {
                      month: "short",
                      day: "numeric"
                    })} `
                    : ""}
                </div>

                <div className="NewJobForm__shift-card-end">
                  {`${new Date(item.start_time).toLocaleString("en-GB", {
                    hour: "numeric",
                    minute: "numeric"
                  })} ${t("jobs.shortTermJobCard.to")} ${new Date(item.end_time).toLocaleString("en-GB", {
                    hour: "numeric",
                    minute: "numeric"
                  })} `}
                </div>
              </div>
              {shiftTotal && (
                <div className="ShortTermJobCard__shift-card-total">
                  {" "}
                  {convertMinsToHrsMins(
                    getTimeBetweenDates(
                      new Date(item.start_time),
                      new Date(item.end_time),
                    ).minutes,
                  )}{" "}
                  total
                </div>
              )}
            </div>
            // </div>
          ))}
        </section>
      )}


      {applicantStatus === "Experience" && (
        <section className="ApplicantSidebar__experience">
          {experience &&
            experience.map((item: any, index: number) => (
              <div className="ApplicantSidebar__experience-item" key={index}>
                <h2 className="ApplicantSidebar__experience-item-title">
                  {item?.job_title}
                </h2>
                <p>{item?.company_name}</p>
                <div className="ApplicantSidebar__experience-item-date">
                  {`${new Date(item?.start_date).toLocaleString("en-GB", {
                    month: "short",
                    year: "numeric"
                  })} - ${item?.end_date === null ? "Present" : new Date(item?.end_date).toLocaleString("en-GB", {
                    month: "short",
                    year: "numeric"
                  })} `}
                  <span>&middot;</span>
                  {item?.end_date === null ? (
                    <span>
                      {`${
                        Math.round(
                          getTimeBetweenDates(
                            new Date(item?.start_date),
                            new Date(),
                          ).days / 365,
                        ) !== 0
                          ? `${Math.round(getTimeBetweenDates(new Date(item?.start_date), new Date()).days / 365)}yr `
                          : ``
                      }`}
                      {`${
                        getTimeBetweenDates(
                          new Date(item?.start_date),
                          new Date(),
                        ).days /
                        365 -
                        Math.round(
                          getTimeBetweenDates(
                            new Date(item?.start_date),
                            new Date(),
                          ).days / 365,
                        ) !==
                        0 &&
                        (
                          getTimeBetweenDates(
                            new Date(item?.start_date),
                            new Date(),
                          ).days /
                          365 -
                          Math.round(
                            getTimeBetweenDates(
                              new Date(item?.start_date),
                              new Date(),
                            ).days / 365,
                          )
                        )
                          .toString()
                          .split(".")[1][0] !== "0"
                          ? ` ${(getTimeBetweenDates(new Date(item?.start_date), new Date()).days / 365 - Math.round(getTimeBetweenDates(new Date(item?.start_date), new Date()).days / 365)).toString().split(".")[1][0]} mos`
                          : ""
                      }`}
                    </span>
                  ) : (
                    <span>
                      {` ${
                        Math.round(
                          getTimeBetweenDates(
                            new Date(item?.start_date),
                            new Date(item?.end_date),
                          ).days / 365,
                        ) !== 0
                          ? `${Math.round(getTimeBetweenDates(new Date(item?.start_date), new Date(item?.end_date)).days / 365)}yr `
                          : ``
                      }`}
                      {`${
                        getTimeBetweenDates(
                          new Date(item?.start_date),
                          new Date(item?.end_date),
                        ).days /
                        365 -
                        Math.round(
                          getTimeBetweenDates(
                            new Date(item?.start_date),
                            new Date(item?.end_date),
                          ).days / 365,
                        ) !==
                        0
                          ? ` ${(getTimeBetweenDates(new Date(item?.start_date), new Date(item?.end_date)).days / 365 - Math.round(getTimeBetweenDates(new Date(item?.start_date), new Date(item?.end_date)).days / 365)).toString().split(".")[1][0]} mos`
                          : ""
                      }`}
                    </span>
                  )}
                </div>
              </div>
            ))}
        </section>
      )}
      {applicantStatus === "Jobs" && (
        <section className="ApplicantSidebar__jobs">
          <div className="ApplicantSidebar__jobs-buttons-container">
            <div
              className={cs(
                `ApplicantSidebar__job-filter-btn`,
                activeButton.active &&
                activeButton.id === 555 &&
                `ApplicantSidebar__job-filter-btn--active`,
              )}
              onClick={() => {
                setActiveButton({
                  id: 555,
                  active: true,
                  name: "all jobs",
                });
                applyFilter("all jobs");
              }}
            >
              {t("jobs.applicantSidebar.jobs.allJobs")}{" "}
              <span>({jobsWithReview.length})</span>
            </div>
            {top5JobsFilter?.map((item: any, index) => (
              <div
                key={index}
                className={cs(
                  `ApplicantSidebar__job-filter-btn`,
                  activeButton.active &&
                  activeButton.id === index &&
                  `ApplicantSidebar__job-filter-btn--active`,
                )}
                onClick={() => {
                  setActiveButton({
                    id: index,
                    active: true,
                    name: item[0].job?.job_title.en,
                  });
                  applyFilter(item[0]?.job?.job_title.en);
                }}
              >
                {item[0].job?.job_title.en} <span>({item.length})</span>
              </div>
            ))}
          </div>
          <div className="ApplicantSidebar__jobs-reviews-container">
            {filterJobs &&
              filterJobs.map((job: any) => (
                <div
                  className="ApplicantSidebar__jobs-review-item"
                  key={job.job.id}
                >
                  <div className="ApplicantSidebar__jobs-review-header">
                    <img
                      src={job?.job?.organization?.logo_url}
                      alt="logo"
                      className="ApplicantSidebar__jobs-review-header-image"
                    />
                    <div>
                      <div className="ApplicantSidebar__jobs-review-header-name">
                        {job?.job?.organization?.name}
                      </div>
                    </div>
                  </div>
                  <div className="ApplicantSidebar__jobs-review-body">
                    {showFullReview?.active &&
                    showFullReview?.id === job?.review?.id ? (
                      <div className="ApplicantSidebar__jobs-review-body-text">
                        {job?.review?.review?.substring(
                          0,
                          job?.review?.review?.length,
                        )}
                      </div>
                    ) : (
                      <div className="ApplicantSidebar__jobs-review-body-text">
                        {job?.review?.review?.substring(0, 52)}...
                      </div>
                    )}

                    {showFullReview?.active &&
                    showFullReview?.id === job?.review?.id ? (
                      <div
                        className="ApplicantSidebar__jobs-review-body-read-more"
                        onClick={() =>
                          setShowFullReview({
                            id: "",
                            active: false,
                          })
                        }
                      >
                        {t("jobs.applicantSidebar.readLess")}
                        <svg
                          className="ApplicantSidebar__arrow-icon"
                          width="10"
                          height="16"
                          viewBox="0 0 10 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="1"
                            d="M9 1L2 8.00029L9 15"
                            stroke="#b2b2b2"
                            strokeWidth="2"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div
                        className="ApplicantSidebar__jobs-review-body-read-more"
                        onClick={() =>
                          setShowFullReview({
                            id: job?.review?.id,
                            active: true,
                          })
                        }
                      >
                        {t("jobs.applicantSidebar.readMore")}
                        <svg
                          className="ApplicantSidebar__arrow-icon"
                          width="10"
                          height="16"
                          viewBox="0 0 10 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="1"
                            d="M9 1L2 8.00029L9 15"
                            stroke="#b2b2b2"
                            strokeWidth="2"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className="ApplicantSidebar__jobs-review-footer">
                    <div className="ApplicantSidebar__body-job-stat-group">
                      <div className="ApplicationCard__body-job-stat">
                        <div className="ApplicationCard__body-job-stat-wrap">
                          <div className="ApplicationCard__stat-label">
                            {t("jobs.applicantSidebar.skills")}
                          </div>
                          <div className="ApplicationCard__stat-number">
                            {job?.review?.skill_rating}
                          </div>
                        </div>
                        <div className="ApplicationCard__stat-line">
                          {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                            <div
                              key={index}
                              className={cs(`ApplicationCard__stat-line-one
                        ${job?.review?.skill_rating === 1 && item === 1 && `ApplicationCard__stat-line-one--red`}
                        ${
                                job?.review?.skill_rating <= 4 &&
                                job?.review?.skill_rating > 1 &&
                                item <= job?.review?.skill_rating &&
                                job?.review?.skill_rating !== null &&
                                `ApplicationCard__stat-line-one--yellow`
                              }
                        ${job?.review?.skill_rating === 5 && `ApplicationCard__stat-line-one--green`}
                        `)}
                            />
                          ))}
                        </div>
                      </div>
                      <div className="ApplicationCard__body-job-stat">
                        <div className="ApplicationCard__body-job-stat-wrap">
                          <div className="ApplicationCard__stat-label">
                            {t("jobs.applicantSidebar.attendance")}
                          </div>
                          <div className="ApplicationCard__stat-number">
                            {job?.review?.attendance_rating}
                          </div>
                        </div>
                        <div className="ApplicationCard__stat-line">
                          {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                            <div
                              key={index}
                              className={cs(`ApplicationCard__stat-line-one
                        ${job?.review?.attendance_rating === 1 && item === 1 && `ApplicationCard__stat-line-one--red`}
                        ${
                                job?.review?.attendance_rating <= 4 &&
                                job?.review?.attendance_rating > 1 &&
                                item <= job?.review?.attendance_rating &&
                                job?.review?.attendance_rating !== null &&
                                `ApplicationCard__stat-line-one--yellow`
                              }
                        ${job?.review?.attendance_rating === 5 && `ApplicationCard__stat-line-one--green`}
                        `)}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="ApplicantSidebar__body-job-stat-group">
                      <div className="ApplicationCard__body-job-stat">
                        <div className="ApplicationCard__body-job-stat-wrap">
                          <div className="ApplicationCard__stat-label">
                            {t("jobs.applicantSidebar.communication")}
                          </div>
                          <div className="ApplicationCard__stat-number">
                            {job?.review?.communication_rating}
                          </div>
                        </div>
                        <div className="ApplicationCard__stat-line">
                          {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                            <div
                              key={index}
                              className={cs(`ApplicationCard__stat-line-one
                        ${job?.review?.communication_rating === 1 && item === 1 && `ApplicationCard__stat-line-one--red`}
                        ${
                                job?.review?.communication_rating <= 4 &&
                                job?.review?.communication_rating > 1 &&
                                item <= job?.review?.communication_rating &&
                                job?.review?.communication_rating !== null &&
                                `ApplicationCard__stat-line-one--yellow`
                              }
                        ${job?.review?.communication_rating === 5 && `ApplicationCard__stat-line-one--green`}
                        `)}
                            />
                          ))}
                        </div>
                      </div>
                      <div className="ApplicationCard__body-job-stat">
                        <div className="ApplicationCard__body-job-stat-wrap">
                          <div className="ApplicationCard__stat-label">
                            {t("jobs.applicantSidebar.teamWork")}
                          </div>
                          <div className="ApplicationCard__stat-number">
                            {job?.review?.teamwork_rating}
                          </div>
                        </div>
                        <div className="ApplicationCard__stat-line">
                          {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                            <div
                              key={index}
                              className={cs(`ApplicationCard__stat-line-one
                        ${job?.review?.teamwork_rating === 1 && item === 1 && `ApplicationCard__stat-line-one--red`}
                        ${
                                job?.review?.teamwork_rating <= 4 &&
                                job?.review?.teamwork_rating > 1 &&
                                item <= job?.review?.teamwork_rating &&
                                job?.review?.teamwork_rating !== null &&
                                `ApplicationCard__stat-line-one--yellow`
                              }
                        ${job?.review?.teamwork_rating === 5 && `ApplicationCard__stat-line-one--green`}
                        `)}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </section>
      )}
      {applicantStatus === "Personal Details" && (
        <section className="ApplicantSidebar__personal-details">
          <div className="ApplicantSidebar__personal-details-item">
            <p className="ApplicantSidebar__personal-details-label">
              {t("jobs.applicantSidebar.personalDetails.gender")}
            </p>
            <p className="ApplicantSidebar__personal-details-value">
              {person?.applicant?.profile.gender === "M" ? "Male" : "Female"}
            </p>
          </div>
          <div className="ApplicantSidebar__personal-details-item">
            <p className="ApplicantSidebar__personal-details-label">
              {t("jobs.applicantSidebar.personalDetails.email")}
            </p>
            <p className="ApplicantSidebar__personal-details-value">
              {person?.applicant?.email}
            </p>
          </div>
          <div className="ApplicantSidebar__personal-details-item">
            <p className="ApplicantSidebar__personal-details-label">
              {t("jobs.applicantSidebar.personalDetails.phoneNumber")}
            </p>
            <p
              className="ApplicantSidebar__personal-details-value"
              onClick={() =>
                person?.applicant?.phone_number && setOpenContactModal(true)
              }
            >
              {person?.applicant?.phone_number ? (
                <>
                  {person.applicant.phone_number}
                  <i
                    className="fa fa-phone"
                    aria-hidden="true"
                    style={{marginLeft: "10px"}}
                  ></i>
                  <i
                    className="fa fa-whatsapp"
                    aria-hidden="true"
                    style={{color: "#25D366", marginLeft: "10px"}}
                  ></i>
                </>
              ) : (
                t("jobs.applicantSidebar.personalDetails.noPhoneNumber")
              )}
            </p>
          </div>
          <div className="ApplicantSidebar__personal-details-item">
            <p className="ApplicantSidebar__personal-details-label">
              {t("jobs.applicantSidebar.personalDetails.birthDate")}
            </p>
            <p className="ApplicantSidebar__personal-details-value">
              {" "}
              {new Date(
                person?.applicant?.profile.date_of_birth,
              ).toLocaleString("en-GB", {
                month: "short",
                year: "numeric",
                day: "numeric",
              })}
            </p>
          </div>
          <div className="ApplicantSidebar__personal-details-item">
            <p className="ApplicantSidebar__personal-details-label">
              {t("jobs.applicantSidebar.personalDetails.location")}
            </p>
            <p className="ApplicantSidebar__personal-details-value">
              {person?.applicant?.profile.address}
            </p>
          </div>
        </section>
      )}
      {applicantStatus === "Education" && (
        <section className="ApplicantSidebar__experience">
          <div className="ApplicantSidebar__personal-details-item">

            <p className="ApplicantSidebar__personal-details-value">
              {person?.education && person.education.length > 0 ? (
                person?.education.map((item: any, index: number) => (
                  <div key={index}>
                    {/*{item.degree} në {item.field_of_study} në{" "}*/}
                    {/*{item.school_name}*/}
                    <div className="ApplicantSidebar__experience-item" key={item.id || 0}>
                      <h2 className="ApplicantSidebar__experience-item-title">
                        {item.degree} në {item.field_of_study}
                      </h2>
                      <p>{item.school_name}</p>
                      <div className="ApplicantSidebar__experience-item-date">
                        {`${new Date(item.start_date).toLocaleString("en-GB", {
                          month: "short",
                          year: "numeric",
                        })} - ${item.end_date === null ? "Present" : new Date(item.end_date).toLocaleString("en-GB", {
                          month: "short",
                          year: "numeric",
                        })}`}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="ApplicantSidebar__experience-item">
                  <div>{t("hasNoEducationYet")}</div>
                </div>
              )}
            </p>

          </div>
        </section>
      )}
      <section className="ApplicantSidebar__footer">
        {person?.status === "accepted" ? (
          <Button
            type="button"
            label={t("jobs.applicantSidebar.personalDetails.cancel")}
            onClick={() =>
              setOpenWarningModal({...openWarningModal, cancel: true})
            }
            className="ApplicantSidebar__accept-btn"
            disabled={isLoading}
          />
        ) : (
          <Button
            type="button"
            label={t("jobs.applicantSidebar.personalDetails.accept")}
            onClick={() => {
              setOpenWarningModal({...openWarningModal, accept: true});
            }}
            className="ApplicantSidebar__accept-btn"
            disabled={isLoading || person.status === "cancelled"}
          />
        )}
      </section>
      {openWarningModal.accept && (
        <WarningModal
          buttonLabel={t("jobs.applicantSidebar.personalDetails.accept")}
          title={
            selectedLanguage === "SQ"
              ? "Jeni i sigurt që dëshironi ta pranoni këtë kandidat?"
              : "Are you sure you want to accept this candidate?"
          }
          onCancel={() =>
            setOpenWarningModal({...openWarningModal, accept: false})
          }
          onSubmit={() => handleAccept()}
        />
      )}
      {openWarningModal.cancel && (
        <WarningModal
          buttonLabel={t("jobs.applicantSidebar.personalDetails.cancel")}
          title={
            selectedLanguage === "SQ"
              ? "Jeni i sigurt që dëshironi ta anuloni këtë kandidat?"
              : "Are you sure you want to cancel this candidate?"
          }
          onCancel={() =>
            setOpenWarningModal({...openWarningModal, cancel: false})
          }
          onSubmit={() => handleCancel()}
          hideCancelBtn={true}
        />
      )}
      {openContactModal && (
        <ContactModal
          phoneNumber={person?.applicant?.phone_number}
          onClose={() => setOpenContactModal(false)}
        />
      )}
    </section>
  );
};

export default ApplicantSidebar;
